import * as appInsights from "@microsoft/applicationinsights-react-js";
import React, { useState } from "react";
import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import * as qs from "qs";

import { tracking } from "../../constants";

/**
 * The useAppInsights hook relies on a real version of ReactDOM#render.
 * Regrettably, using the render function from @testing-library/react
 * breaks this hook and breaks all our tests.
 *
 * This sets our hooks to stubs in test mode, and operate normally otherwise
 */
let useAppInsightsContext;
let useTrackEvent;
if (process.env.NODE_ENV === "test") {
  useAppInsightsContext = function stub() {};
  useTrackEvent = function genStub() {
    return () => {};
  };
} else {
  useAppInsightsContext = appInsights.useAppInsightsContext;
  useTrackEvent = appInsights.useTrackEvent;
}

// The ids for each locale must map to the directories in public/locales
export const locales = [
  {
    id: "en",
    value: "English",
  },
  {
    id: "es",
    value: "Spanish - Español",
  },
  {
    id: "am",
    value: "Amharic - አማርኛ",
  },
  {
    id: "ar",
    value: "Arabic - العَرَبِيةُ",
  },
  {
    id: "hy",
    value: "Armenian - հայերէն",
  },
  {
    id: "my",
    value: "Burmese - မြန်မာ",
  },
  {
    id: "ca",
    value: "Catalan - Català",
  },
  {
    id: "zh-CN",
    value: "Chinese (Simplified) - 简体中文",
  },
  {
    id: "zh-TW",
    value: "Chinese (Traditional) - 繁體中文",
  },
  {
    id: "chk",
    value: "Chuukese - Fosun Chuuk",
  },
  {
    id: "prs",
    value: "Dari - دری",
  },
  {
    id: "fa",
    value: "Farsi - فارسی",
  },
  {
    id: "fr",
    value: "French - Français",
  },
  {
    id: "de",
    value: "German - Deutsch",
  },
  {
    id: "ht",
    value: "Haitian Creole - Kreyòl ayisyen",
  },
  {
    id: "he",
    value: "Hebrew - עברית",
  },
  {
    id: "hi",
    value: "Hindi - हिन्दी",
  },
  {
    id: "hmn",
    value: "Hmong - Hmoob",
  },
  {
    id: "ja",
    value: "Japanese - 日本語",
  },
  {
    id: "kar",
    value: "Karen - ကညီ",
  },
  {
    id: "km",
    value: "Khmer - ភាសាខ្មែរ",
  },
  {
    id: "ko",
    value: "Korean - 한국어",
  },
  {
    id: "lo",
    value: "Lao - ພາ​ສາ​ລາວ",
  },
  {
    id: "mh",
    value: "Marshallese - Kajin Ṃajeḷ",
  },
  {
    id: "mxb",
    value: "Mixteco Bajo - Ñuu savi",
  },
  {
    id: "ne",
    value: "Nepali - नेपाली",
  },
  {
    id: "om",
    value: "Oromo - Oromiffa",
  },
  {
    id: "ps",
    value: "Pashto - پښتو",
  },
  {
    id: "pt-BR",
    value: "Portuguese - Português (Brasil)",
  },
  {
    id: "pa",
    value: "Punjabi - ਪੰਜਾਬੀ",
  },
  {
    id: "ro",
    value: "Romanian - Română",
  },
  {
    id: "ru",
    value: "Russian - Русский",
  },
  {
    id: "sm",
    value: "Samoan - Faa-Samoa",
  },
  {
    id: "so",
    value: "Somali - Af Soomaali",
  },
  {
    id: "sw",
    value: "Swahili - Kiswahili",
  },
  {
    id: "tl",
    value: "Tagalog",
  },
  {
    id: "ta",
    value: "Tamil - தமிழ்",
  },
  {
    id: "te",
    value: "Telugu - తెలుగు",
  },
  {
    id: "th",
    value: "Thai - ภาษาไทย",
  },
  {
    id: "ti",
    value: "Tigrinya - ትግርኛ",
  },
  {
    id: "uk",
    value: "Ukrainian - Українська мова",
  },
  {
    id: "ur",
    value: "Urdu - اُردُو",
  },
  {
    id: "vi",
    value: "Vietnamese - Tiếng Việt",
  },
];

const supportedLocales = new Set(locales.map(({ id }) => id));
export { supportedLocales };

const useStyles = makeStyles((theme) => ({
  languages: {
    color: theme.palette.common.white,
  },
}));

export function LanguageSelect() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const track = useTrackEvent(
    useAppInsightsContext(),
    tracking.LANGUAGE_SELECTED
  );

  const qsParams = qs.parse(location.search.replace(/^\?/, ""));

  const handleOpen = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLanguageChange = (e) => {
    const { id } = e.currentTarget;
    track({ id });

    // Update the language query parameter, but only if it's already specified.
    // The LocationDetection component will detect URL and i18n language are out
    // of sync and will change the language accordingly.
    // Otherwise do it the normal way without touching the URL.
    if ("language" in qsParams) {
      // Don't drop any query params that may exist in the URL
      const newParams = qs.stringify({ ...qsParams, language: id });

      history.push(`${location.pathname}?${newParams}`);
    } else {
      i18n.changeLanguage(id);
    }

    // TODO: find solution for translating updated langauge
    // moment.updateLocale(i18n.language, {
    //   relativeTime: {
    //     past: t("relativeTimePast"),
    //     s: t("relativeTimeS"),
    //   },
    // });
    handleClose();
  };

  return (
    <>
      <Button
        aria-controls={Boolean(anchorEl) ? "language-selection" : undefined}
        aria-haspopup="true"
        aria-label="Select language"
        className={classes.languages}
        onClick={handleOpen}
        startIcon={<Language />}
        variant="text"
      >
        {t("nativeLanguageName")}
      </Button>
      <Menu
        id="language-selection"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {locales.map((l) => (
          <MenuItem
            key={l.value}
            aria-label={l.value}
            id={l.id}
            onClick={handleLanguageChange}
          >
            {l.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
