import React from "react";

import { CircularProgress, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 320,
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      className={classes.container}
      container
      justify="center"
    >
      <CircularProgress aria-label="Loading content" aria-live="assertive" />
    </Grid>
  );
};

export default LoadingSpinner;
