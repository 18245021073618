import PropTypes from "prop-types";
import React, {useMemo, useState} from "react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { searchQueryString } from "./Filters";

import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

import { tracking } from "../../constants";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      flexWrap: "nowrap",
    },
  },
  submitContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      marginLeft: theme.spacing(2),
    },
    "&.inline": {
      flexDirection: "row",
    },
  },
  searchInput: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
      maxWidth: 340,
    },
  },
  searchButton: {
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      height: 56,
      marginBottom: theme.spacing(1),
    },
    "&.inline": {
      marginBottom: 0,
    },
  },
  searchDisclaimer: {
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
    },
    "&.inline": {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    color: theme.palette.text.disabled,
  },
}));

/**
 * SearchInput handles presentation and interaction of the zip code search
 * input. It is built to make look, feel, helper text, and state handling behave
 * consistently across multiple uses.
 *
 * It expects an optional initial value --
 * useful in ZIP-specific result pages where the initial search is already set.
 *
 * @params {String} props.initialValue an optional initial value for the search input
 */

export default function SearchInput({ initialValue = "", inline = false }) {
  const appInsights = useAppInsightsContext();
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);
  const [helperText, setHelperText] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const params = useLocation().search;
  const filters = qs.parse(params, { ignoreQueryPrefix: true });

  const vaccineTypeFilters =  useMemo(() =>
      filters.vaccineTypes ? filters.vaccineTypes.split(',') : [],
    [filters.vaccineTypes]
  );

  const schedulingFilters =  useMemo(() =>
      filters.schedulingFilters ? filters.schedulingFilters.split(',') : [],
    [filters.schedulingFilters]
  );

  const accessibilityFilters =  useMemo(() =>
      filters.accessibilityFilters ? filters.accessibilityFilters.split(',') : [],
    [filters.accessibilityFilters]
  );

  const cysFilters =  useMemo(() =>
      filters.cysFilters ? filters.cysFilters.split(',') : [],
    [filters.cysFilters]
  );

  const validZips = useSelector((state) => state.locationFinder.zips);

  const clearSearch = () => setValue("");

  const invalidZipSearched = useTrackEvent(
    appInsights,
    tracking.INVALID_ZIP_SEARCHED
  );

  const handleSubmit = () => {
    if (!value) {
      return setHelperText(t("searchRequiredMessage"));
    } else {
      setHelperText("");
    }
    if (!isEmpty(validZips) && !(value in validZips)) {
      // TODO translate validation message
      invalidZipSearched({ zip: value });
      return setHelperText(t("inputValidationInvalidZip"));
    }

    const queryString = qs.stringify({
      vaccineTypes: vaccineTypeFilters,
      schedulingFilters: schedulingFilters,
      accessibilityFilters: accessibilityFilters,
      cysFilters: cysFilters
    }, { arrayFormat: 'comma' });

    history.push(`/locations/${value}?${queryString}`);
  };

  const handleChange = (e) => {
    if (helperText && e.target.value) setHelperText("");

    setValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.which === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={classes.container}>
      <TextField
        className={classes.searchInput}
        error={Boolean(helperText)}
        fullWidth
        helperText={helperText}
        inputProps={{
          "aria-label": t("searchLabel"),
        }}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              <IconButton
                aria-label="Clear search input"
                className={classes.searchIcon}
                edge="end"
                onClick={clearSearch}
              >
                <Cancel />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={t("searchPlaceholder")}
        value={value}
        variant="outlined"
      />
      <div
        className={clsx(classes.submitContainer, {
          inline,
        })}
      >
        <Button
          className={clsx(classes.searchButton, {
            inline,
          })}
          color="primary"
          disableElevation
          onClick={handleSubmit}
          variant="contained"
        >
          {t("submitButton")}
        </Button>
        <Typography
          className={clsx(classes.searchDisclaimer, {
            inline,
          })}
          variant="caption"
        >
          {t("submitDisclaimer")}
        </Typography>
      </div>
    </div>
  );
}

SearchInput.propTypes = {
  initialValue: PropTypes.string,
  inline: PropTypes.bool,
};
