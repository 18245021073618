const tracking = {
  ELIGIBILITY_CLICKED: 'Eligibility Clicked',
  EMPTY_RESULTS_SHOWN: 'Empty Results Shown',
  INVALID_ZIP_SEARCHED: 'Invalid Zip Searched',
  LANGUAGE_SELECTED: 'Language Selected',
  LOCATIONS_BOOKING_LINK_CLICKED: 'Locations Booking Link Clicked',
  LOCATIONS_SEARCHED: 'Locations Searched',
  OLD_APP_LINK_CLICKED: 'Old App Link Clicked',
  LOAD_MORE_BUTTON_CLICKED: 'Load More Button Clicked',
  DOH_NEWSROOM_LINK_CLICKED: 'DOH Newsroom Link Clicked',
  LOCATIONS_EMAIL_COPIED: 'Locations Email Copied',
  LOCATIONS_PHONE_LINK_CLICKED: 'Locations Phone Link Clicked',
  LOCATIONS_VIEW_MORE_INFO_CLICKED: 'Locations View More Info Clicked',
  LOCATIONS_VIEW_LESS_INFO_CLICKED: 'Locations Less Info Clicked',
  CLEAR_FILTERS_BUTTON_CLICKED: 'Clear Filters Button Clicked',
  OPEN_FILTERS_BUTTON_CLICKED: 'Open Filters Button Clicked',
  OPEN_VACCINE_TYPE_FILTERS_BUTTON_CLICKED: 'Open Vaccine Type Filters Button Clicked',
  FEEDBACK_FORM_SUBMITTED: 'Feedback Form Submitted',
};

export { tracking };

const links = {
  FEEDBACK_EMAIL: 'covid.vaccine@doh.wa.gov',
  OLD_VACCINE_LOCATOR_URL: 'https://www.doh.wa.gov/YouandYourFamily/Immunization/VaccineLocations',
  COVIDWA_URL: 'https://www.covidwa.com/',
  DOH_NEWSROOM_URL: 'https://www.doh.wa.gov/Newsroom/Articles/ID/2737/Statement-on-Pausing-J-J-Vaccine',
  VACCINEFINDER_URL: 'https://vaccinefinder.org/',
  DOH_EMERGENCIES_COVID19_VACCINE: 'https://www.doh.wa.gov/Emergencies/COVID19/Vaccine',
};

export { links };

const routes = {
  LOCATION_FINDER_PATH: '/',
  LOCATION_DETAIL_PATH: '/locations',
  LOCATION_RESULTS_PATH: '/locations/:zip',
};

export { routes };

// Models the enumerated appointment availability options for a location
// cf. VACCS-API: ./api/src/models/constants.js
const vaccineAvailability = {
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
  UNKNOWN: 'UNKNOWN',
};

export { vaccineAvailability };

const filterProperty = {
  VACCINE_TYPES: 'vaccineTypes',
  SCHEDULING_FILTERS: 'schedulingFilters',
  ACCESSIBILITY_FILTERS: 'accessibilityFilters',
  CYS_FILTERS: 'cysFilters',
};

export { filterProperty };

const validFilters = {
  [filterProperty.VACCINE_TYPES]: [
    // { value: "pfizerTod"},
    // { value: "modernaTod"},
    { value: 'pfizerTodBiv' },
    { value: 'modTodBiv' },
    // { value: "pfizerPed"},
    // { value: "modernaPed"},
    { value: 'pfizerPedBiv' },
    { value: 'modernaBiv' },
    // { value: "pfizer" },
    // { value: "moderna" },
    { value: 'pfizerBiv' },
    // { value: "johnsonAndJohnson" },
    { value: 'novavax' },
  ],
  [filterProperty.SCHEDULING_FILTERS]: [
    // scheduling preferences
    { value: 'scheduleOnline' },
    { value: 'scheduleByPhone' },
    { value: 'scheduleByEmail' },
    { value: 'walkIn' },
    { value: 'waitList' },
  ],
  [filterProperty.ACCESSIBILITY_FILTERS]: [
    // accessibility
    { value: 'walkupSite' },
    { value: 'waitingArea' },
    { value: 'accessibleParking' },
    { value: 'wheelchairAccessible' },
    { value: 'commCardAvailable' },
    { value: 'commCardBrailleAvailable' },
    { value: 'interpretersAvailable' },
    { value: 'additionalSupports' },
    { value: 'driveupSite' },
  ],
  [filterProperty.CYS_FILTERS]: [
    // cys
    { value: 'provideInfantVaccine' },
    { value: 'providePediatricVaccine' },
    { value: 'provideYouthVaccine' },
    { value: 'consentLegallyEmancipated' },
    { value: 'consentFosterParent' },
    { value: 'consentResponsibleRelative' },
    { value: 'consentMatureMinor' },
    { value: 'consentVerbal' },
    { value: 'consentWritten' },
    { value: 'consentFromParentGuardianAuthAdul' },
    { value: 'consentSignedAuth' },
    { value: 'consentNurseCounselorLiai' },
  ],
};

export { validFilters };

const all = { links, routes, tracking, vaccineAvailability, validFilters };
export default all;
