import React, { useLayoutEffect } from "react";
import { create } from "jss";
import rtl from "jss-rtl";

import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core";

import useDirectionTheme from "../theme/useDirectionTheme";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const MuiThemeProvider = ({ children }) => {
  const theme = useDirectionTheme();

  useLayoutEffect(() => {
    document.body.setAttribute("dir", theme.direction);
  }, [theme.direction]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default MuiThemeProvider;
