import React, { useCallback, useEffect, useMemo } from "react";
import { parse } from "qs";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { supportedLocales } from "../Header/LanguageSelect";

const LanguageDetection = (props) => {
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const language = useMemo(() => parse(search.replace(/^\?/, '')).language, [search])
  const isSupportedLanguage = useMemo(() => supportedLocales.has(language), [
    language,
  ]);
  const currentLanguage = i18n.language;
  console.log("🚀 ~ file: index.js:16 ~ LanguageDetection ~ currentLanguage:", currentLanguage)

  const changeLanguage = useCallback(() => i18n.changeLanguage(language), [i18n, language]);

  useEffect(() => {
    if (language && language !== currentLanguage && isSupportedLanguage) {
      changeLanguage(language);
    }
  }, [currentLanguage, language, isSupportedLanguage, changeLanguage])

  return (
    <>
      {props.children}
    </>
  );
}

export default LanguageDetection;
