import PropTypes from "prop-types";
import React, {useCallback, useMemo} from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Box } from "@material-ui/core";
import LocationCard from "./LocationCard";
import { TrackedButton } from "../../common/TrackedComponents";
import { tracking } from "../../constants";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../common/LoadingSpinner";
import Schema from "./schema/AppointmentLocations";
import {useLocation} from "react-router-dom";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  screenReaderFocus: {
    clip: "rect(1px, 1px, 1px, 1px)",
    height: "1px",
    width: "1px",
    overflow: "hidden",
    position: "absolute",
  },
  loadMoreButtonContainer: {
    marginTop: theme.spacing(5),
  },
}));

const SearchResults = ({
  locations,
  submittedValue,
  paging,
  loadMore,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pageNum, pageSize } = paging;
  const params = useLocation().search;
  const filters = qs.parse(params, { ignoreQueryPrefix: true });

  const vaccineTypeFilters =  useMemo(() =>
      filters.vaccineTypes ? filters.vaccineTypes.split(',') : [],
    [filters.vaccineTypes]
  );

  const schedulingFilters =  useMemo(() =>
      filters.schedulingFilters ? filters.schedulingFilters.split(',') : [],
    [filters.schedulingFilters]
  );

  const accessibilityFilters =  useMemo(() =>
      filters.accessibilityFilters ? filters.accessibilityFilters.split(',') : [],
    [filters.accessibilityFilters]
  );

  const cysFilters =  useMemo(() =>
      filters.cysFilters ? filters.cysFilters.split(',') : [],
    [filters.cysFilters]
  );

  const onClickLoadMore = useCallback(() => {
    const nextPage = pageNum + 1;
    dispatch(loadMore({ searchValue: submittedValue, pageNum: nextPage, vaccineTypes: vaccineTypeFilters, schedulingFilters, accessibilityFilters, cysFilters } ));
  }, [dispatch, loadMore, pageNum, submittedValue]);

  const pageDiff =
    locations.length % pageSize === 0 ? pageSize : locations.length % pageSize;

  const ifIndexIsFirstOfNextLoadedPage = (idx) =>
    pageNum > 1 && idx === (pageNum - 1) * pageSize - 1;

  return (
    <>
      {locations.map((l, idx) => (
        <React.Fragment key={`${l.locationId}-${l.zipcode}`}>
          <LocationCard location={l} resultPosition={idx + 1} />
          {ifIndexIsFirstOfNextLoadedPage(idx) && (
            <p
              aria-live="polite"
              className={classes.screenReaderFocus}
            >
              {t("loadedMore", { pageDiff })}
            </p>
          )}
        </React.Fragment>
      ))}
      <Schema locations={locations} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        locations.length < paging.total && (
          <Box className={classes.loadMoreButtonContainer} textAlign="center">
            <TrackedButton
              onClick={onClickLoadMore}
              color="secondary"
              event={tracking.LOAD_MORE_BUTTON_CLICKED}
              size="large"
              variant="outlined"
            >
              {t("loadMoreLocations")}
            </TrackedButton>
          </Box>
        )
      )}
    </>
  );
};

SearchResults.propTypes = {
  locations: PropTypes.array,
  submittedValue: PropTypes.string,
};

export default SearchResults;
