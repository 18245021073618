import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { TrackedButton } from "../../../../common/TrackedComponents";
import { tracking } from "../../../../constants";
import { setFeedbackFormLocation } from "../../locationFinderSlice";

export const WebsiteFullLink = ({ href, hasAvailability, eventTags, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = () => {
    dispatch(setFeedbackFormLocation(location));
  };

  return (
    <TrackedButton
      color="primary"
      disableElevation
      event={tracking.LOCATIONS_BOOKING_LINK_CLICKED}
      fullWidth
      href={href}
      onClick={handleClick}
      rel="noopener noreferrer"
      size="large"
      tags={eventTags}
      target="_blank"
      variant={hasAvailability ? "contained" : "outlined"}
    >
      {t("goToSiteButton")}
    </TrackedButton>
  )
};

WebsiteFullLink.propTypes = {
  href: PropTypes.string,
  event: PropTypes.string,
  eventTags: PropTypes.object,
};
