import { createMuiTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import palette from "./palette";

const fontFamilyDefault = '"Open Sans", "Arial", "Helvetica Neue", sans-serif';

const useDirectionTheme = () => {
  const { t } = useTranslation();
  const direction = t("direction");

  return createMuiTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
    },
    direction,
    mixins: {
      toolbar: {
        minHeight: 56,
        padding: "0 4px 0 16px",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          padding: "10px 16px",
        },
      },
      MuiLink: {
        underlineHover: {
          textDecoration: "underline",
          "&:hover": {
            fontWeight: 600,
            letterSpacing: "normal",
          },
        },
      },
    },
    palette,
    props: {},
    shadows: [
      "none",
      "0px 1px 3px 0px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 2px 1px -1px rgba(0,0,0,0.06)",
      "0px 1px 5px 0px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 3px 1px -2px rgba(0,0,0,0.06)",
      "0px 1px 8px 0px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.07),0px 3px 3px -2px rgba(0,0,0,0.06)",
      "0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)",
      "0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.06)",
      "0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)",
      "0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)",
      "0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)",
      "0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)",
      "0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)",
      "0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)",
      "0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)",
      "0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)",
      "0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)",
      "0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)",
      "0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)",
      "0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)",
      "0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)",
      "0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)",
      "0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)",
      "0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)",
      "0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)",
    ],
    shape: { borderRadius: 5 },
    transitions: {
      easing: {
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
        easeIn: "cubic-bezier(0.4, 0, 1, 1)",
        sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
      },
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195,
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: fontFamilyDefault,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      fontWeightExtraBold: 800,
      h1: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "3.75rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      h2: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "3rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      h3: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "2.125rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      h4: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "1.5rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      h5: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      h6: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      subtitle1: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "1.25rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      subtitle2: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      body1: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      body2: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      button: {
        fontFamily: fontFamilyDefault,
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "normal",
        letterSpacing: "normal",
        textTransform: "none",
      },
      caption: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
      overline: {
        fontFamily: fontFamilyDefault,
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: "normal",
        letterSpacing: "normal",
      },
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
  });
};

export default useDirectionTheme;
