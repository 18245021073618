import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "@material-ui/core";

import LogoEN from "./LogoEN";
import LogoES from "./LogoES";
import { routes } from "../../constants";

const Logo = ({ height = 70, inverted = false }) => {
  const theme = useTheme();
  const { language } = useTranslation().i18n;
  const fill = inverted
    ? theme.palette.secondary.main
    : theme.palette.common.white;

  return (
    <Link to={routes.LOCATION_FINDER_PATH} aria-label="Vaccinate WA Home">
      {language === "es" ? (
        <LogoES fill={fill} height={height} />
      ) : (
        <LogoEN fill={fill} height={height} />
      )}
    </Link>
  );
};

Logo.propTypes = {
  height: PropTypes.number,
  inverted: PropTypes.bool,
};

export default Logo;
