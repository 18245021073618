import { createSlice } from "@reduxjs/toolkit";

import client from "../../app/client";
import { searchLocations, findZips } from "../../app/queries";
import { vaccineAvailability } from "../../constants";

const { AVAILABLE, UNKNOWN } = vaccineAvailability;

const availabilityToOrdinal = (availability) => {
  switch (availability) {
    case AVAILABLE:
      return 1;
    case UNKNOWN:
      return 2;
    default:
      return 3;
  }
};

export const locationFinderSlice = createSlice({
  name: "locationFinder",
  initialState: {
    isLoading: false,
    locations: [],
    feedbackFormLocation: null,
    paging: {},
    summary: [],
    zips: {},
    isError: false,
  },
  reducers: {
    resetLocations: (state) => {
      state.locations = [];
    },
    setFeedbackFormLocation: (state, action) => {
      state.feedbackFormLocation = action.payload;
    },
    setLocations: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.locations.push(...action.payload);
    },
    setPaging: (state, action) => {
      state.paging = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setZips: (state, action) => {
      // Note, a Set would be more ideal, but is not serializable by Redux
      state.zips = (action.payload || []).reduce(
        (memo, zip) => ({ ...memo, [zip]: true }),
        {}
      );
    },
    toggleSpinner: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.isError = action.payload;
    },
  },
});

export const {
  resetLocations,
  setFeedbackFormLocation,
  setLocations,
  setPaging,
  setSummary,
  setZips,
  toggleSpinner,
  setError,
} = locationFinderSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchLocations('54321'))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const fetchLocations = ({ searchValue, pageNum = 1, pageSize = 20, vaccineTypes, schedulingFilters, accessibilityFilters, cysFilters }) => (dispatch) => {
  dispatch(setError(false));
  dispatch(toggleSpinner(true));
  pageNum === 1 && dispatch(resetLocations([]));

  client
    .query({
      query: searchLocations,
      variables: {
        searchInput: {
          originZip: searchValue,
          paging: {
            pageNum,
            pageSize,
          },
          ...(vaccineTypes.length > 0 && { vaccineTypes }), // only send vaccineTypes if there are any applied
          ...(schedulingFilters.length > 0 && { schedulingFilters }), // only send schedulingFilters if there are any applied
          ...(accessibilityFilters.length > 0 && { accessibilityFilters }), // only send accessibilityFilters if there are any applied
          ...(cysFilters.length > 0 && { cysFilters }) // only send cysFilters if there are any applied

        },
      },
    })
    .then((result) => {
      const { data, loading, networkStatus } = result;

      if (loading) return;

      if (networkStatus !== 7) {
        console.warn(`Unhandled network status ${networkStatus}`);
        return;
      }

      const { locations, paging, summary } = data.searchLocations;

      const locationsWithDistance = locations.map((l) => {

        return {
          ...l,
          formattedDistance: l.distance.toFixed(1),
          availabilityOrdinal: availabilityToOrdinal(l.vaccineAvailability),
        };
      });
      dispatch(setLocations(locationsWithDistance));
      dispatch(setPaging(paging));
      dispatch(setSummary(summary));
    })
    .catch((error) => {
      console.error(error);
      dispatch(setError(true))
    })
    .finally(() => dispatch(toggleSpinner(false)));
};

export const fetchZips = () => async (dispatch) => {
  try {
    const response = await client.query({ query: findZips });
    dispatch(setZips(response.data.findZipCodes));
  } catch (err) {
    // Eat this error. If this fails, we just don't do validation
    console.error(err);
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.locationFinder.value)`
// export const selectLocations = (state) => state.locationFinder.locations;

export default locationFinderSlice.reducer;
