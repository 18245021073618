import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

/**
 * Initializes and configures our integration with Azure App insights. The
 * REACT_APP_APPLICATION_INSIGHTS_IKEY must be available in the environment at
 * build time.
 *
 * Tracking will not work otherwise and the whole system will fail gracefully
 * without affecting the app in development mode.
 *
 * The documentation does make mention of integrating with browser history to
 * facilitate tracking page changes in SPAs, but we don't have more than one
 * page right now.
 *
 * https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
 */

const reactPlugin = new ReactPlugin()
const history = createBrowserHistory({ basename: '' })
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_IKEY || '',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history }
    },
    samplingPercentage: 100 // send everything; TODO reduce as we ramp up to avoid throttling
  }
})
appInsights.loadAppInsights()

export { reactPlugin, appInsights }
