import React from "react";

import { Container, Grid, Typography, makeStyles } from "@material-ui/core";

import InlineBoldTrans from "../../common/InlineBoldTrans";

const useStyles = makeStyles((theme) => ({
  notification: {
    background: theme.palette.accent.tealLighter,
    padding: theme.spacing(2),
    wordWrap: 'break-word',
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      padding: theme.spacing(1, 2),
    },
  },
}));

const NotificationBanner = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.notification} item xs={12}>
      <Container disableGutters maxWidth="md">
        <Typography>
          <InlineBoldTrans i18nKey="hotlineNotification" />
        </Typography>
      </Container>
    </Grid>
  );
};

export default NotificationBanner;
