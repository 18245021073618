import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './App.css';
import BuildVersion from './common/BuildVersion';
import Footer from './common/Footer';
import GoogleAnalytics from './common/vendors/GoogleAnalytics';
import Header from './common/Header';
import LanguageDetection from './common/LanguageDetection';
import LocationFinder from './features/locationFinder/LocationFinder';
import LocationResults from './features/locationFinder/LocationResults';
import { fetchZips } from './features/locationFinder/locationFinderSlice';
import { routes } from './constants';
import AccessibilityText from './features/locationFinder/AccessibilityText';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchZips());
  }, [dispatch]);

  // NOTE: keep any new routes synced with SitemapRoutes.js as well to
  // accurately generate sitemaps.
  return (
    <>
      <BuildVersion />
      <GoogleAnalytics />
      <Router>
        <LanguageDetection>
          <Header />
          <main>
            <Switch>
              <Route exact path={routes.LOCATION_FINDER_PATH}>
                <LocationFinder />
              </Route>
              <Route exact path={routes.LOCATION_RESULTS_PATH}>
                <LocationResults />
              </Route>
              <Route path='*'>
                <Redirect to={routes.LOCATION_FINDER_PATH} />
              </Route>
            </Switch>
          </main>
          <Footer />
          {/*<AccessibilityText />*/}
        </LanguageDetection>
      </Router>
    </>
  );
};

export default App;
