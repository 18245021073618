import PropTypes from "prop-types";
import React, { useMemo, createContext } from "react";
import { includes } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import {
  makeStyles,
  Container,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { validFilters, filterProperty } from "../../../constants";
import qs from "qs";
import FiltersDesktop from "./FiltersDesktop";
import FiltersMobile from "./FiltersMobile";

export const filtersContext = createContext();
const { VACCINE_TYPES, SCHEDULING_FILTERS, ACCESSIBILITY_FILTERS, CYS_FILTERS } = filterProperty;
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: `${theme.spacing(2)}px auto ${theme.spacing(1)}px`,
  },
}));

const mapFilterData = (validFilterList, filterProperty, selectedFilters) => validFilterList
  .map(filter => ({
    ...filter,
    filterProperty,
    isSelected: includes(selectedFilters, filter.value)
  }));

const getListOfSelectedFilterValues = (updatedList, filterProperty) => updatedList
    .filter(type => type.isSelected === true && type.filterProperty === filterProperty)
    .map(type => type.value);

export default function Filters({ intialValue = {} }) {
  const { vaccineTypes, schedulingFilters, accessibilityFilters, cysFilters } = intialValue;
  const classes = useStyles();
  const history = useHistory();
  const { zip } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openVF, setOpenVF] = React.useState(false);
  const [openSP, setOpenSP] = React.useState(false);
  const [openAC, setOpenAC] = React.useState(false);
  const [openCYS, setOpenCYS] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [filtersData, setFiltersData] = React.useState({
    vaccineTypes: mapFilterData(validFilters.vaccineTypes, VACCINE_TYPES, vaccineTypes),
    schedulingFilters: mapFilterData(validFilters.schedulingFilters, SCHEDULING_FILTERS, schedulingFilters),
    accessibilityFilters: mapFilterData(validFilters.accessibilityFilters, ACCESSIBILITY_FILTERS, accessibilityFilters),
    cysFilters: mapFilterData(validFilters.cysFilters, CYS_FILTERS, cysFilters),
  });

  const filtersAppliedCount =  useMemo(() => vaccineTypes.length + schedulingFilters.length + accessibilityFilters.length + cysFilters.length,
    [vaccineTypes, schedulingFilters, accessibilityFilters, cysFilters]
  );

  const searchQueryString = ((updatedFiltersData) => {
    const hasSelectedFilters = [...updatedFiltersData.vaccineTypes, ...updatedFiltersData.schedulingFilters,  ...updatedFiltersData.accessibilityFilters, ,  ...updatedFiltersData.cysFilters]
      .some(val => val.isSelected === true)

    if (hasSelectedFilters) {
      const vaccineFilters = getListOfSelectedFilterValues(updatedFiltersData.vaccineTypes, VACCINE_TYPES);
      const schedulingFilters = getListOfSelectedFilterValues(updatedFiltersData.schedulingFilters, SCHEDULING_FILTERS);
      const accessibilityFilters = getListOfSelectedFilterValues(updatedFiltersData.accessibilityFilters, ACCESSIBILITY_FILTERS);
      const cysFilters = getListOfSelectedFilterValues(updatedFiltersData.cysFilters, CYS_FILTERS);

      const queryString = qs.stringify({
        vaccineTypes: vaccineFilters,
        schedulingFilters: schedulingFilters,
        accessibilityFilters: accessibilityFilters,
        cysFilters: cysFilters
      }, { arrayFormat: 'comma' });

      return `?${queryString}`;
    }
    return ''
  });


  const handleClick = (chipToDelete, filterProperty) => () => {
    const updatedData = filtersData;

    updatedData[filterProperty] = updatedData[filterProperty].map(item => {
      if (item.value === chipToDelete.value) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    setFiltersData(updatedData);

    history.push({
      pathname: `/locations/${zip}`,
      search: searchQueryString(updatedData),
    });
  };

  const clearFilters = () => {
    const clearedFilters = {
      vaccineTypes: mapFilterData(validFilters.vaccineTypes, VACCINE_TYPES, []),
      schedulingFilters: mapFilterData(validFilters.schedulingFilters, SCHEDULING_FILTERS, []),
      accessibilityFilters: mapFilterData(validFilters.accessibilityFilters, ACCESSIBILITY_FILTERS, []),
      cysFilters: mapFilterData(validFilters.cysFilters, CYS_FILTERS, []),
    }

    setFiltersData(clearedFilters)
    history.push({ pathname: `/locations/${zip}` });
  }

  return (
    <filtersContext.Provider
      value={{
        open,
        setOpen,
        openVF,
        setOpenVF,
        openSP,
        setOpenSP,
        openAC,
        setOpenAC,
        openCYS,
        setOpenCYS,
        handleClick,
        clearFilters,
        filtersAppliedCount,
        filtersData,
      }}
    >
      <Container className={classes.container} >
        {isMobile ? <FiltersMobile /> : <FiltersDesktop />}
      </Container>
    </filtersContext.Provider>

  );
}

Filters.propTypes = {
  initialValue: PropTypes.object,
};
