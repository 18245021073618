// See docs: https://www.apollographql.com/docs/react/get-started/

// TODO: maybe add GraphQL persisted queries. This blog post describes it, but
// uses older NPM packages with slightly different API:
//
// https://www.apollographql.com/blog/caching-graphql-results-in-your-cdn-54299832b8e2/
//
// The README for the newer (but still beta) @apollo/link-persisted-queries NPM
// shows how to use the pattern with the newer API:
//
// https://www.npmjs.com/package/@apollo/link-persisted-queries

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import fetch from 'cross-fetch';
// import { createPersistedQueryLink } from "@apollo/link-persisted-queries"

// const link = concat(
//   createPersistedQueryLink({ useGETForHashedQueries: true }),
//   new HttpLink({ uri: process.env.REACT_APP_API_URI })
// )
const API_URI = process.env.REACT_APP_API_URI || 'https://apim-vaccs-dev.azure-api.net/web/graphql';
// const API_URI = process.env.REACT_APP_API_URI || 'http://localhost:4000/graphql';
// Don't send credentials if developing against the API in localhost
const credentials = /localhost/.test(API_URI) ? '' : 'include';

export default new ApolloClient({
  // See: https://www.apollographql.com/docs/react/caching/cache-configuration/
  cache: new InMemoryCache({
    typePolicies: {
      Location: {
        keyFields: ['locationId'],
      },
    },
  }),
  // link: link
  link: createHttpLink({
    uri: API_URI,
    credentials,
    fetch,
  }),
});
