import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  mockButton: {
    background: "red",
    position: "fixed",
    width: 45,
    height: 45,
    borderRadius: "50%",
    right: 16,
    bottom: 40,
    [theme.breakpoints.up("sm")]: {
      width: 65,
      height: 65,
    },
  },
  text: {
    position: "fixed",
    right: 4,
    bottom: 8,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      right: 13,
    },
  },
}));

const AccessibilityText = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  // Reference the Wiki for how to translate the text when needed

  return (
    <>
      {/* <div className={classes.mockButton} /> */}
      <Typography className={classes.text} variant="caption">
        {t('accessibilityLabel')} <br /> {t('accessibilityMenuCaption')}
      </Typography>
    </>
  );
};

export default AccessibilityText;
