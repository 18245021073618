import React from 'react';

import { AppBar, makeStyles, Toolbar } from '@material-ui/core';

import { LanguageSelect } from './LanguageSelect';
import Logo from '../Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} component='header' position='static'>
      <Toolbar className={classes.toolbar}>
        <Logo height={40} />
        <div>{/*<LanguageSelect />*/}</div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
