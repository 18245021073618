import React from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

function SearchActions () {
  return (
    <Helmet
      script={[
        // https://developers.google.com/search/docs/data-types/sitelinks-searchbox
        helmetJsonLdProp({
          '@context': "https://schema.org",
          '@type': 'WebSite',
          url: 'https://vaccinelocator.doh.wa.gov/',
          potentialAction: {
            '@type': 'SearchAction',
            target: 'https://vaccinelocator.doh.wa.gov/locations/{zip}',
            'query-input': 'required name=zip'
          }
        })
      ]}
    />
  );
}

export default SearchActions;
