import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { difference, findIndex, first, get, isEmpty } from "lodash";
import { Container, Grid, Typography, makeStyles } from "@material-ui/core";
import LoadingSpinner from "../../../common/LoadingSpinner";
import NotificationBanner from "../NotificationBanner";
import SEO from "../../../common/SEO";
import SearchInput from "../SearchInput";
import Filters from "../Filters"
import SearchResults from "../SearchResults";
import { fetchLocations, setFeedbackFormLocation } from "../locationFinderSlice";
import { routes, tracking, validFilters } from "../../../constants";
import useEventTracker from "../../../hooks/useEventTracker";
import usePrevious from "../../../hooks/usePrevious";
import qs from "qs";
import { vaccineAvailability } from "../../../constants";
import clsx from "clsx";
const { AVAILABLE } = vaccineAvailability;

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    padding: theme.spacing(3, 2, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 0, 0),
    },
  },
  background: {
    backgroundColor: theme.palette.accent.purpleLightest,
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
    },
  },
  list: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      margin: "0 auto",
      padding: theme.spacing(3, 0, 8),
    },
  },
  message: {
    marginBottom: theme.spacing(3),
  },
  errorMessage: {
    margin: theme.spacing(2),
  }
}));

const LocationResults = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { zip } = useParams();
  const params = useLocation().search;
  const filters = qs.parse(params, { ignoreQueryPrefix: true });

  const {
    isLoading,
    feedbackFormLocation,
    locations,
    zips: validZips,
    paging,
    summary,
    isError,
  } = useSelector((state) => state.locationFinder);

  const showFeedbackForm = useMemo(
    () => !isEmpty(feedbackFormLocation),
    [feedbackFormLocation]
  );
  const handleModalClose = () => {
    dispatch(setFeedbackFormLocation(null))
  };
  
  const handleModalSubmit = (payload) => {
    trackFeedbackFormSubmission({payload});
    handleModalClose();
  };
  // Determine loading state
  const previousIsLoading = usePrevious(isLoading);
  const doneLoading = previousIsLoading === true && isLoading === false;

  // Set up event trackers
  const trackLocationsSearched = useEventTracker(tracking.LOCATIONS_SEARCHED);
  const trackEmptyResultsShown = useEventTracker(tracking.EMPTY_RESULTS_SHOWN);
  const trackInvalidZipSearched = useEventTracker(tracking.INVALID_ZIP_SEARCHED);
  const trackFeedbackFormSubmission = useEventTracker(tracking.FEEDBACK_FORM_SUBMITTED);

  // Check if zips are loaded && validity of currently searched zip
  const areValidZipsLoaded = useMemo(() => !isEmpty(validZips), [validZips]);
  const isZipValid = useMemo(() => {
    if (!areValidZipsLoaded) return false;
    return !!validZips[zip];
  }, [areValidZipsLoaded, validZips, zip]);

  // Check if vaccine filters are valid
  const vaccineTypeFilters =  useMemo(() =>
      filters.vaccineTypes ? filters.vaccineTypes.split(',') : [],
    [filters.vaccineTypes]
  );

  // Check if scheduling filters are valid
  const schedulingFilters =  useMemo(() =>
      filters.schedulingFilters ? filters.schedulingFilters.split(',') : [],
    [filters.schedulingFilters]
  );

  // Check if accessibility filters are valid
  const accessibilityFilters =  useMemo(() =>
      filters.accessibilityFilters ? filters.accessibilityFilters.split(',') : [],
    [filters.accessibilityFilters]
  );

  // Check if cys filters are valid
  const cysFilters =  useMemo(() =>
      filters.cysFilters ? filters.cysFilters.split(',') : [],
    [filters.cysFilters]
  );

  const areFiltersValid = useMemo(() => {

    const invalidTypeFilters = difference(vaccineTypeFilters, validFilters.vaccineTypes.map(filter => filter.value));
    const invalidSchedulingFilters = difference(schedulingFilters, [...validFilters.schedulingFilters].map(filter => filter.value));
    const invalidAccessibilityFilters = difference(accessibilityFilters, [...validFilters.accessibilityFilters].map(filter => filter.value));
    const invalidCysFilters = difference(cysFilters, [...validFilters.cysFilters].map(filter => filter.value));
    return invalidTypeFilters.length === 0 && invalidAccessibilityFilters.length === 0 && invalidSchedulingFilters.length === 0 && invalidCysFilters.length === 0
  }, [vaccineTypeFilters, schedulingFilters, accessibilityFilters, cysFilters]);

  // Grab aggregate calculations from locations
  const locationsCount = paging.total;
  const nearestLocationDistance = useMemo(
    () => get(first(locations), "distance", null),
    [locations]
  );
  const availabilityCount = useMemo(() => {
    const availabilitySummaryIndex = findIndex(
      summary,
      (o) => o.vaccineAvailability === AVAILABLE
    );
    return get(summary, `[${availabilitySummaryIndex}].total`);
  }, [summary]);

  const locationAvailabilityRatio = useMemo(() => {
    return availabilityCount / paging.total;
  }, [availabilityCount, paging]);

  // Effect: After results have loaded, log that a search occurred (with or without results)
  useEffect(() => {
    if (!doneLoading) return;

    if (locationsCount === 0) {
      trackEmptyResultsShown({ language, zip });
    } else
      trackLocationsSearched({
        availabilityCount,
        language,
        locationAvailabilityRatio,
        nearestLocationDistance,
        zip,
      });
  }, [
    zip,
    doneLoading,
    trackEmptyResultsShown,
    locationsCount,
    trackLocationsSearched,
    locationAvailabilityRatio,
    availabilityCount,
    language,
    nearestLocationDistance,
  ]);

  // Effect: If in invalid zip or filter has been provided, log and redirect
  useEffect(() => {
    if ((!isZipValid && areValidZipsLoaded) || !areFiltersValid) {
      trackInvalidZipSearched(zip);
      history.push(routes.LOCATION_FINDER_PATH);
    }
  }, [zip, isZipValid, areValidZipsLoaded, areFiltersValid, trackInvalidZipSearched, history]);

  // Effect: If a valid zip & filter has been provided, fetch locations
  useEffect(() => {
    if (isZipValid && areFiltersValid) {
      dispatch(fetchLocations({ searchValue: zip, vaccineTypes: vaccineTypeFilters, schedulingFilters: schedulingFilters, accessibilityFilters: accessibilityFilters, cysFilters: cysFilters }));
    }
  }, [zip, isZipValid, vaccineTypeFilters, schedulingFilters, accessibilityFilters, cysFilters, areFiltersValid, dispatch]);

  const isLoadingFirstPage = isLoading && locations.length === 0;

  return (
    <>
      <SEO
        description={t("pageDescriptionSearchResults", { searchValue: zip })}
        title={t("pageTitleSearchResults", { searchValue: zip } )}
      />
      <NotificationBanner />
      <Container className={classes.searchContainer} maxWidth="md">
        <SearchInput initialValue={zip} inline />
        <Filters
          intialValue={{
            vaccineTypes: vaccineTypeFilters,
            schedulingFilters: schedulingFilters,
            accessibilityFilters: accessibilityFilters,
            cysFilters: cysFilters
          }}
        />
      </Container>
      {isLoadingFirstPage ? (
        <LoadingSpinner />
      ) : (
        <Grid className={clsx(classes.background)} item xs={12}>
          <Container
            className={classes.listContainer}
            disableGutters
            maxWidth="md"
          >
            <Grid className={classes.list} item xs={12} sm={9} md={7}>
              { locations.length > 0 && (
                <>
                  <Typography
                    aria-live="polite"
                    className={classes.message}
                    color="textSecondary"
                    component="h1"
                    variant="h6"
                  >
                    {paging.total}{" "}
                    {t("resultsMessage", {
                      searchValue: zip,
                    })}
                  </Typography>
                  <SearchResults
                    locations={locations}
                    paging={paging}
                    submittedValue={zip}
                    loadMore={fetchLocations}
                    isLoading={isLoading}
                  />
                </>
              )}

              { locations.length == 0 && (
                <>
                  <Typography
                    aria-live="polite"
                    className={classes.message}
                    color="textSecondary"
                    component="h1"
                    variant="h6"
                  >
                    {t("noFacilityMatches")}
                  </Typography>
                  <SearchResults
                    locations={locations}
                    paging={paging}
                    submittedValue={zip}
                    loadMore={fetchLocations}
                    isLoading={isLoading}
                  />
                </>
              )}

              {isError && (
                <Typography
                  aria-live="polite"
                  className={classes.errorMessage}
                  component="h1"
                  variant="h6"
                  color="error"
                >
                  {t("error")}
                </Typography>
              )}
            </Grid>
          </Container>
        </Grid>
      )}
    </>
  );
};

export default LocationResults;
