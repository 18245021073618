import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

const InlineBoldTrans = ({ i18nKey }) => (
  <Trans
    i18nKey={i18nKey}
    components={{
      bold: <strong />,
    }}
  />
);

InlineBoldTrans.propTypes = {
  i18nKey: PropTypes.string,
};

export default InlineBoldTrans;
