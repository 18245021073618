import PropTypes from "prop-types";
import React from "react";
import { tracking } from "../../../constants";
import { TrackedButton } from "../../../common/TrackedComponents";
import { useTranslation } from "react-i18next";


export default function ClearFiltersButton({ disabled, onClick, fullWidth }) {
  const { t } = useTranslation();

  return (
    <TrackedButton
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      color="primary"
      disableElevation
      event={tracking.CLEAR_FILTERS_BUTTON_CLICKED}
      size="large"
      variant={"outlined"}
    >
      {t("clearFilters")}
    </TrackedButton>
  );
}

ClearFiltersButton.propTypes = {
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clearFilters: PropTypes.func,
};
