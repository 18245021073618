import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  fillTeal: {
    fill: "#30a197",
  },
  fillBlack: {
    fill: "#000000",
  },
}));

const VaccineFinderLogo = ({ width = 50 }) => {
  const classes = useStyles();

  return (
    <svg
      width={width}
      viewBox="0 0 200 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={classes.fillTeal}
        d="M6.55,23.61,0,2.21l0-.12c0-.21.11-.32.35-.32h4a.42.42,0,0,1,.44.32L9,17.53c0,.07,0,.1.09.1s.08,0,.1-.1L13.33,2.09a.42.42,0,0,1,.44-.32h3.89A.34.34,0,0,1,18,1.9a.33.33,0,0,1,0,.31l-6.62,21.4a.4.4,0,0,1-.41.32H7A.4.4,0,0,1,6.55,23.61Z"
      />
      <path
        className={classes.fillTeal}
        d="M28.74,8.12a6,6,0,0,1,2.52,1.94,4.56,4.56,0,0,1,.9,2.76V23.55a.4.4,0,0,1-.11.27.39.39,0,0,1-.27.11h-3.7a.37.37,0,0,1-.27-.11.36.36,0,0,1-.11-.27V22.5a.16.16,0,0,0-.07-.13s-.08,0-.12.07a5,5,0,0,1-4.28,1.74,6.28,6.28,0,0,1-4-1.2,4.27,4.27,0,0,1-1.51-3.55,4.56,4.56,0,0,1,1.7-3.78,7.72,7.72,0,0,1,4.88-1.34h3.23a.15.15,0,0,0,.16-.17v-.69a2.12,2.12,0,0,0-.64-1.63,2.7,2.7,0,0,0-1.9-.59,3.48,3.48,0,0,0-1.63.35,1.64,1.64,0,0,0-.84,1,.36.36,0,0,1-.41.32l-3.83-.51c-.25,0-.37-.13-.35-.28a4.22,4.22,0,0,1,1.07-2.41A6.21,6.21,0,0,1,21.63,8a9.2,9.2,0,0,1,3.44-.61A8.94,8.94,0,0,1,28.74,8.12Zm-2,12a2.27,2.27,0,0,0,.95-1.87V17.12a.14.14,0,0,0-.16-.16H25.26A4,4,0,0,0,23,17.5,1.73,1.73,0,0,0,22.22,19a1.62,1.62,0,0,0,.6,1.34,2.47,2.47,0,0,0,1.62.49A3.68,3.68,0,0,0,26.75,20.13Z"
      />
      <path
        className={classes.fillTeal}
        d="M38.06,22.91a6.91,6.91,0,0,1-2.64-3.48,11.56,11.56,0,0,1-.54-3.73A11.14,11.14,0,0,1,35.42,12a6.54,6.54,0,0,1,2.63-3.39,7.73,7.73,0,0,1,4.4-1.24,7.87,7.87,0,0,1,4.44,1.27,6,6,0,0,1,2.55,3.2A4,4,0,0,1,49.7,13v.06a.34.34,0,0,1-.32.35L45.74,14h-.06a.38.38,0,0,1-.38-.32l-.1-.51a2.4,2.4,0,0,0-1-1.36,3.05,3.05,0,0,0-1.81-.54,2.79,2.79,0,0,0-1.7.51,2.6,2.6,0,0,0-1,1.39,7.61,7.61,0,0,0-.35,2.6,8.49,8.49,0,0,0,.31,2.59,2.83,2.83,0,0,0,1,1.49,2.76,2.76,0,0,0,1.72.54,3,3,0,0,0,1.73-.52,2.67,2.67,0,0,0,1-1.44.5.5,0,0,0,0-.19.13.13,0,0,0,0-.1c0-.25.17-.36.45-.31l3.64.56c.21,0,.31.16.31.35a4.19,4.19,0,0,1-.19.95A5.9,5.9,0,0,1,46.86,23a8.12,8.12,0,0,1-4.41,1.2A7.56,7.56,0,0,1,38.06,22.91Z"
      />
      <path
        className={classes.fillTeal}
        d="M54.87,22.91a6.91,6.91,0,0,1-2.64-3.48,11.56,11.56,0,0,1-.54-3.73A11.14,11.14,0,0,1,52.23,12a6.49,6.49,0,0,1,2.63-3.39,7.73,7.73,0,0,1,4.4-1.24A7.89,7.89,0,0,1,63.7,8.67a6,6,0,0,1,2.55,3.2A3.86,3.86,0,0,1,66.5,13v.06a.33.33,0,0,1-.31.35L62.55,14h-.07a.4.4,0,0,1-.38-.32L62,13.13a2.4,2.4,0,0,0-1-1.36,3.05,3.05,0,0,0-1.81-.54,2.84,2.84,0,0,0-1.71.51,2.71,2.71,0,0,0-1,1.39,7.87,7.87,0,0,0-.35,2.6,8.45,8.45,0,0,0,.32,2.59,2.71,2.71,0,0,0,2.72,2A3,3,0,0,0,61,19.83a2.67,2.67,0,0,0,1-1.44.5.5,0,0,0,0-.19.13.13,0,0,0,0-.1c0-.25.17-.36.44-.31l3.65.56c.21,0,.31.16.31.35a4.19,4.19,0,0,1-.19.95A5.9,5.9,0,0,1,63.67,23a8.15,8.15,0,0,1-4.41,1.2A7.56,7.56,0,0,1,54.87,22.91Z"
      />
      <path
        className={classes.fillTeal}
        d="M69.72,5.11A2.42,2.42,0,0,1,69,3.32a2.51,2.51,0,1,1,5,0,2.53,2.53,0,0,1-4.29,1.79Zm-.38,18.71a.36.36,0,0,1-.11-.27V8a.33.33,0,0,1,.11-.27.34.34,0,0,1,.27-.12h3.7a.36.36,0,0,1,.27.12.37.37,0,0,1,.11.27V23.55a.4.4,0,0,1-.11.27.39.39,0,0,1-.27.11h-3.7A.37.37,0,0,1,69.34,23.82Z"
      />
      <path
        className={classes.fillTeal}
        d="M89.74,9a5.72,5.72,0,0,1,1.55,4.21V23.55a.38.38,0,0,1-.38.38H87.2a.38.38,0,0,1-.38-.38V14.08A2.91,2.91,0,0,0,86.11,12a2.4,2.4,0,0,0-1.88-.8,2.43,2.43,0,0,0-1.89.8,3,3,0,0,0-.71,2.05v9.47a.36.36,0,0,1-.11.27.37.37,0,0,1-.27.11h-3.7a.37.37,0,0,1-.27-.11.4.4,0,0,1-.11-.27V8a.37.37,0,0,1,.11-.27.33.33,0,0,1,.27-.11h3.7a.38.38,0,0,1,.38.38v1.1c0,.07,0,.11.07.13a.06.06,0,0,0,.09,0A4.44,4.44,0,0,1,85.65,7.4,5.56,5.56,0,0,1,89.74,9Z"
      />
      <path
        className={classes.fillTeal}
        d="M108.92,16.81a.36.36,0,0,1-.41.37H98.73c-.13,0-.17.06-.13.16A5.34,5.34,0,0,0,99,18.61c.55,1.18,1.67,1.77,3.35,1.77a3.82,3.82,0,0,0,3.2-1.64.37.37,0,0,1,.29-.2c.06,0,.13.05.22.13l2.28,2.25a.45.45,0,0,1,.16.28.73.73,0,0,1-.13.26,7,7,0,0,1-2.69,2,9.08,9.08,0,0,1-3.61.71A8.26,8.26,0,0,1,97.41,23a6.92,6.92,0,0,1-2.7-3.46A10.85,10.85,0,0,1,94,15.35a9,9,0,0,1,.48-3.07A6.91,6.91,0,0,1,97.07,8.7a7.09,7.09,0,0,1,4.32-1.33,7,7,0,0,1,5.08,1.84,8.09,8.09,0,0,1,2.33,4.91A14.35,14.35,0,0,1,108.92,16.81ZM98.79,13.1a7.68,7.68,0,0,0-.22,1c0,.1.05.15.16.15h5.47c.13,0,.17,0,.13-.15-.11-.62-.16-.91-.16-.89a2.73,2.73,0,0,0-1-1.47,2.92,2.92,0,0,0-1.76-.53A2.55,2.55,0,0,0,98.79,13.1Z"
      />
      <path
        className={classes.fillBlack}
        d="M127.2,5.49a.37.37,0,0,1-.27.11H116.58a.14.14,0,0,0-.16.16v4.9a.14.14,0,0,0,.16.16h6.81a.38.38,0,0,1,.38.38v3.07a.37.37,0,0,1-.11.27.36.36,0,0,1-.27.11h-6.81a.14.14,0,0,0-.16.16v8.74a.36.36,0,0,1-.11.27.37.37,0,0,1-.27.11h-3.7a.39.39,0,0,1-.27-.11.4.4,0,0,1-.11-.27V2.15a.37.37,0,0,1,.11-.27.39.39,0,0,1,.27-.11h14.59a.37.37,0,0,1,.27.11.33.33,0,0,1,.11.27V5.22A.36.36,0,0,1,127.2,5.49Z"
      />
      <path
        className={classes.fillBlack}
        d="M130,5.11a2.41,2.41,0,0,1-.71-1.79,2.43,2.43,0,0,1,.69-1.8,2.68,2.68,0,0,1,3.61,0,2.4,2.4,0,0,1,.7,1.8A2.53,2.53,0,0,1,130,5.11Zm-.38,18.71a.36.36,0,0,1-.11-.27V8a.33.33,0,0,1,.11-.27.34.34,0,0,1,.27-.12h3.7a.36.36,0,0,1,.27.12A.37.37,0,0,1,134,8V23.55a.4.4,0,0,1-.11.27.39.39,0,0,1-.27.11h-3.7A.37.37,0,0,1,129.64,23.82Z"
      />
      <path
        className={classes.fillBlack}
        d="M150,9a5.72,5.72,0,0,1,1.55,4.21V23.55a.4.4,0,0,1-.11.27.39.39,0,0,1-.27.11h-3.7a.37.37,0,0,1-.27-.11.37.37,0,0,1-.12-.27V14.08a2.91,2.91,0,0,0-.71-2.05,2.61,2.61,0,0,0-3.76,0,2.91,2.91,0,0,0-.72,2.05v9.47a.38.38,0,0,1-.37.38h-3.71a.38.38,0,0,1-.38-.38V8a.37.37,0,0,1,.11-.27.36.36,0,0,1,.27-.11h3.71a.33.33,0,0,1,.26.11.33.33,0,0,1,.11.27v1.1c0,.07,0,.11.07.13a.06.06,0,0,0,.09,0A4.45,4.45,0,0,1,146,7.4,5.56,5.56,0,0,1,150,9Z"
      />
      <path
        className={classes.fillBlack}
        d="M164.9,1.88a.37.37,0,0,1,.27-.11h3.7a.37.37,0,0,1,.27.11.33.33,0,0,1,.11.27v21.4a.36.36,0,0,1-.11.27.37.37,0,0,1-.27.11h-3.7a.37.37,0,0,1-.27-.11.4.4,0,0,1-.11-.27V22.5c0-.06,0-.1-.06-.11a.14.14,0,0,0-.13.05A4.34,4.34,0,0,1,161,24.18,6.09,6.09,0,0,1,157.14,23a6.85,6.85,0,0,1-2.29-3.39,12.34,12.34,0,0,1-.54-3.83,12.24,12.24,0,0,1,.57-3.93,6.8,6.8,0,0,1,2.3-3.21,6.22,6.22,0,0,1,3.88-1.19A4.29,4.29,0,0,1,164.6,9c0,.06.08.08.13.06s.06-.06.06-.13V2.15A.37.37,0,0,1,164.9,1.88Zm-.14,13.91a6.58,6.58,0,0,0-.7-3.32,2.32,2.32,0,0,0-2.12-1.24,2.47,2.47,0,0,0-2.25,1.24,6.13,6.13,0,0,0-.79,3.35,6.67,6.67,0,0,0,.67,3.17,2.54,2.54,0,0,0,2.34,1.36A2.44,2.44,0,0,0,164.12,19,6.68,6.68,0,0,0,164.76,15.79Z"
      />
      <path
        className={classes.fillBlack}
        d="M187.14,16.81a.37.37,0,0,1-.42.37h-9.78c-.12,0-.17.06-.12.16a5.34,5.34,0,0,0,.35,1.27c.54,1.18,1.66,1.77,3.35,1.77a3.83,3.83,0,0,0,3.2-1.64.35.35,0,0,1,.28-.2.35.35,0,0,1,.22.13l2.28,2.25a.41.41,0,0,1,.16.28.65.65,0,0,1-.12.26,7.09,7.09,0,0,1-2.69,2,9.12,9.12,0,0,1-3.61.71A8.26,8.26,0,0,1,175.63,23a6.94,6.94,0,0,1-2.71-3.46,10.87,10.87,0,0,1-.66-4.14,9.26,9.26,0,0,1,.47-3.07,7,7,0,0,1,2.55-3.58,7.11,7.11,0,0,1,4.32-1.33,7,7,0,0,1,5.08,1.84A8,8,0,0,1,187,14.12,13.51,13.51,0,0,1,187.14,16.81ZM177,13.1a7.53,7.53,0,0,0-.23,1,.14.14,0,0,0,.16.15h5.48c.13,0,.17,0,.13-.15-.11-.62-.16-.91-.16-.89a2.69,2.69,0,0,0-1-1.47,2.89,2.89,0,0,0-1.76-.53A2.53,2.53,0,0,0,177,13.1Z"
      />
      <path
        className={classes.fillBlack}
        d="M199.8,7.88a.39.39,0,0,1,.19.47L199.32,12c0,.25-.17.33-.44.22a3.73,3.73,0,0,0-1.11-.15,5.61,5.61,0,0,0-.7.06,3,3,0,0,0-1.92.84,2.57,2.57,0,0,0-.8,2v8.64a.36.36,0,0,1-.11.27.37.37,0,0,1-.27.11h-3.7a.39.39,0,0,1-.27-.11.4.4,0,0,1-.11-.27V8a.37.37,0,0,1,.11-.27.35.35,0,0,1,.27-.11H194a.38.38,0,0,1,.38.38v1.2a.13.13,0,0,0,.07.13s.07,0,.09,0A4,4,0,0,1,198,7.44,3.19,3.19,0,0,1,199.8,7.88Z"
      />
    </svg>
  );
};

export default VaccineFinderLogo;
