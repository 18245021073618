// These two polyfills must be imported before everything else to enable IE support
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Provider } from "react-redux";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import "./app/i18n";
import "./index.css";
import App from "./App";
import LoadingSpinner from "./common/LoadingSpinner";
import MuiThemeProvider from "./common/MuiThemeProvider";
import { reactPlugin } from "./common/ApplicationInsights";
import * as serviceWorker from "./serviceWorker";
import store from "./app/store";

const root = document.getElementById("root")
const renderFn = root.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render

const renderApp = () =>
  renderFn(
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Provider store={store}>
          <Suspense fallback={<LoadingSpinner />}>
            <MuiThemeProvider>
              <App />
            </MuiThemeProvider>
          </Suspense>
        </Provider>
      </AppInsightsContext.Provider>
    </React.StrictMode>,
    root
  );

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
  renderApp();
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
