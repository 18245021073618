import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { isFunction } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  useAppInsightsContext,
  useTrackEvent
} from '@microsoft/applicationinsights-react-js'

import { Button, Link } from '@material-ui/core'

/**
 * Tracked components wrap standard Material UI trackable components. Each
 * wrapped component receives tracking parameters as properties, add a tracking
 * handler, and forwards everything else to the underlying component:
 *
 * @param {String} event the tracking event name to send to Azure App Insights
 * @param {Object} tags an optional key/value tags to add detail to the event
 */

const COMPONENTS = {
  BUTTON: Button,
  LINK: Link
}

const TrackedComponent = forwardRef((props, ref) => {
  const { Component, children, event, onClick, ...rest } = props
  const { i18n } = useTranslation()
  const track = useTrackEvent(useAppInsightsContext(), event)

  const handleClick = React.useCallback(() => {
    const tags = Object.entries(props.tags).reduce(
      (memo, [key, val]) => ({
        ...memo,
        [key]: isFunction(val) ? val() : val
      }),
      {}
    )
    onClick && onClick()
    track({
      ...tags,
      language: i18n.language
    })
  }, [props.tags, onClick, i18n.language, track])

  return (
    <Component onClick={handleClick} ref={ref} {...rest}>
      {children}
    </Component>
  )
})

export const TrackedLink = forwardRef((props, ref) => (
  <TrackedComponent Component={COMPONENTS.LINK} ref={ref} {...props} />
))

TrackedLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]),
  event: PropTypes.string,
  href: PropTypes.string,
  tags: PropTypes.object
}

TrackedLink.defaultProps = {
  tags: {}
}

export const TrackedButton = forwardRef((props, ref) => (
  <TrackedComponent Component={COMPONENTS.BUTTON} ref={ref} {...props} />
))

TrackedButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]),
  event: PropTypes.string,
  href: PropTypes.string,
  tags: PropTypes.object,
  onClick: PropTypes.func
}

TrackedButton.defaultProps = {
  tags: {}
}
