import React from "react";
import { Helmet } from 'react-helmet'

const BuildVersion = () => {
  const sha = process.env.REACT_APP_BUILD_SHA;
  if (!sha) {
    return null;
  }

  return (
    <Helmet>
      <meta name="version" value={sha} />
    </Helmet>
  );
}

export default BuildVersion;
