import { useCallback } from "react";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useEventTracker = (eventName) => {
  const appInsights = useAppInsightsContext();

  const eventTracker = useTrackEvent(appInsights, eventName);

  // Memoize repetitive calls to the tracker with the same function args
  const tracker = useCallback(
    (eventData) => {
      eventTracker(eventData);
    },
    [eventTracker]
  );

  return tracker;
};

export default useEventTracker;
