import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { helmetJsonLdProp } from "react-schemaorg";

// TODO DRY violation. Taken from LocationCard/index.js
// move to shared util?
const locationAddress = location => {
  const { addressLine1, addressLine2, city, state, zipcode } = location;
  let address = addressLine1;

  if (addressLine2) address += ` ${addressLine2}`;

  return `${address}, ${city}, ${state} ${zipcode}`;
};

const cleanEmptyProperties = obj => Object.entries(obj)
  .filter(entry => !!entry[1])
  .reduce(
    (memo, [key, val]) => ({ ...memo, [key]: val }),
    {}
  );

function AppointmentLocations (props) {
  const { locations } = props;


  return (
    <Helmet
      script={[
        helmetJsonLdProp({
          '@context': "https://schema.org",
          '@graph': locations.map(location =>
            // https://schema.org/LocalBusiness
            cleanEmptyProperties({
              '@type': 'LocalBusiness',
              address: locationAddress(location),
              email: location.email,
              name: location.locationName,
              telephone: location.phone,
              url: location.schedulingLink,
            })
          )
        })
      ]}
    />
  );
}

AppointmentLocations.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default AppointmentLocations;
