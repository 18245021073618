import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { TrackedLink } from "../../../../common/TrackedComponents";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2, 1),
  },
  contactItemText: {
    marginLeft: theme.spacing(1),
  },
  contactButton: {
    marginLeft: "auto",
    textDecoration: "none",
  },
  contactText: {
    fontWeight: 600,
  },
}));

export const ContactButton = forwardRef((props, ref) => {
  const { icon, displayText, href, buttonText, event, eventTags } = props;
  const classes = useStyles();
  return (
    <div className={classes.contactItem}>
      {icon}
      <Typography
        className={classes.contactItemText}
        component="p"
        variant="body1"
      >
        {displayText}
      </Typography>
      <TrackedLink
        ref={ref}
        target="_blank"
        rel="noopener noreferrer"
        tags={eventTags}
        className={classes.contactButton}
        href={href}
        color="primary"
        size={"small"}
        event={event}
      >
        <Typography
          className={classes.contactText}
          component="p"
          variant="body1"
        >
          {buttonText}
        </Typography>
      </TrackedLink>
    </div>
  );
});

ContactButton.propTypes = {
  Icon: PropTypes.any,
  displayText: PropTypes.string,
  buttonText: PropTypes.string,
  href: PropTypes.string,
  event: PropTypes.string,
  eventTags: PropTypes.object,
};
