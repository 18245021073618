import React from "react";

const LogoES = ({ fill, height }) => (
  <svg
    height={height}
    viewBox="0 0 200 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      d="M95.66,61.63H87.09L84.52,49.94c-.12-.49-.31-1.46-.57-2.92s-.46-2.67-.58-3.65c-.1.8-.26,1.78-.48,3s-.44,2.27-.65,3.26-1.1,5-2.67,12H71L64.36,34.8h7l2.92,13.45q1,4.45,1.36,7.1c.15-1.25.44-2.94.85-5.08s.79-3.91,1.15-5.32L80,34.8h6.72L89,45c.39,1.63.79,3.5,1.19,5.63s.68,3.72.81,4.77q.24-2,1.3-7.06l3-13.49h7Z"
    />
    <path
      fill={fill}
      d="M119.94,61.62l-1.33-5H109.9l-1.36,5h-8l8.75-26.93H119l8.86,26.93Zm-2.83-11L116,46.25c-.26-1-.59-2.24-1-3.8s-.64-2.66-.76-3.34c-.11.63-.33,1.66-.65,3.09s-1,4.25-2.16,8.45Z"
    />
    <path
      fill="#009EA1"
      d="M147.72,53.2l6.51-8.52L147.73,38a10.11,10.11,0,0,0-7.3-3.25,8.19,8.19,0,0,0-5.69,2.2l-.91.86c-3.49,3.3-3.36,9.14.3,13L145.6,62.59c0-.27-.11-.53-.15-.8a11.57,11.57,0,0,1,2.27-8.59"
    />
    <path
      fill="#009EA1"
      d="M182.29,37l-8.53-6.48a1,1,0,0,0-.58-.2h-.13a1,1,0,0,0-.64.37l-9.54,12.55a1,1,0,0,0,.18,1.36l8.53,6.48a1,1,0,0,0,1.35-.19l9.54-12.54a1,1,0,0,0-.18-1.36"
    />
    <path
      fill="#009EA1"
      d="M194.28,13.65l-1.09-.83a9.69,9.69,0,0,0-13.57,1.81L149.25,54.37a9.71,9.71,0,0,0,1.82,13.57l1.08.83A9.69,9.69,0,0,0,165.72,67l30.37-39.74a9.69,9.69,0,0,0-1.81-13.57m-9,2.3a1.06,1.06,0,1,1-.9,1.19,1.06,1.06,0,0,1,.9-1.19m-3,4a1.06,1.06,0,1,1,.29,2.09,1.06,1.06,0,0,1-.29-2.09M179.25,24a1.06,1.06,0,0,1,.3,2.09,1.06,1.06,0,1,1-.3-2.09M158.8,50.49a1.06,1.06,0,1,1-.89,1.19,1.05,1.05,0,0,1,.89-1.19M153,60.64a1.06,1.06,0,1,1,.9-1.19,1.06,1.06,0,0,1-.9,1.19m3.08-4.09a1.06,1.06,0,1,1,.9-1.2,1.06,1.06,0,0,1-.9,1.2m2,4.7a1.05,1.05,0,1,1-.3-2.08,1.05,1.05,0,1,1,.3,2.08M160,65.94a1.06,1.06,0,1,1,.89-1.19,1.05,1.05,0,0,1-.89,1.19m1.1-8.65a1.06,1.06,0,1,1,.9-1.19,1.06,1.06,0,0,1-.9,1.19m2,4.56a1.06,1.06,0,1,1,.89-1.19,1.05,1.05,0,0,1-.89,1.19m3-4a1.06,1.06,0,1,1,.9-1.19,1.06,1.06,0,0,1-.9,1.19m17.75-18.51-9.55,12.54A2.63,2.63,0,0,1,172.16,53a2.66,2.66,0,0,1-1.6-.54L162,45.94a2.66,2.66,0,0,1-.5-3.72l9.54-12.55a2.64,2.64,0,0,1,3.71-.5l8.53,6.48a2.67,2.67,0,0,1,.51,3.72m.82-12.65a1.06,1.06,0,1,1,.89-1.19,1.05,1.05,0,0,1-.89,1.19m1.88,4.68a1.05,1.05,0,1,1-.29-2.08,1.06,1.06,0,0,1,1.19.89,1.05,1.05,0,0,1-.9,1.19m1.1-8.65a1.06,1.06,0,1,1,.9-1.19,1.06,1.06,0,0,1-.9,1.19m2,4.56a1.05,1.05,0,0,1-1.19-.89,1.06,1.06,0,1,1,1.19.89m3-4a1.07,1.07,0,0,1-1.2-.89,1.06,1.06,0,1,1,1.2.89"
    />
    <path
      fill="white"
      d="M188.52,21.56a1.06,1.06,0,1,0-.9,1.19,1.05,1.05,0,0,0,.9-1.19"
    />
    <path
      fill="white"
      d="M185.53,25.53a1.05,1.05,0,1,0-.89,1.19,1.05,1.05,0,0,0,.89-1.19"
    />
    <path
      fill="white"
      d="M185.61,18a1.06,1.06,0,1,0-1.19-.9,1.05,1.05,0,0,0,1.19.9"
    />
    <path
      fill="white"
      d="M182.62,22a1.06,1.06,0,1,0-1.19-.9,1.06,1.06,0,0,0,1.19.9"
    />
    <path
      fill="white"
      d="M179.54,26.1a1.06,1.06,0,1,0-.29-2.09,1.06,1.06,0,0,0,.29,2.09"
    />
    <path
      fill="white"
      d="M192.29,21.26a1.05,1.05,0,0,0-.9,1.19,1.06,1.06,0,0,0,1.19.89,1.05,1.05,0,1,0-.29-2.08"
    />
    <path
      fill="white"
      d="M189.3,25.22a1.06,1.06,0,0,0-.89,1.19,1.05,1.05,0,1,0,.89-1.19"
    />
    <path
      fill="white"
      d="M186.22,29.32a1.05,1.05,0,1,0,1.19.89,1.05,1.05,0,0,0-1.19-.89"
    />
    <path
      fill="white"
      d="M162,56.09a1.06,1.06,0,1,0-.9,1.19,1.06,1.06,0,0,0,.9-1.19"
    />
    <path
      fill="white"
      d="M159,60.06a1.05,1.05,0,1,0-.89,1.19,1.05,1.05,0,0,0,.89-1.19"
    />
    <path
      fill="white"
      d="M159.1,52.58a1.06,1.06,0,1,0-1.19-.9,1.06,1.06,0,0,0,1.19.9"
    />
    <path
      fill="white"
      d="M157,55.35a1.06,1.06,0,1,0-.9,1.19,1.06,1.06,0,0,0,.9-1.19"
    />
    <path
      fill="white"
      d="M152.74,58.55a1.06,1.06,0,1,0,1.19.9,1.06,1.06,0,0,0-1.19-.9"
    />
    <path
      fill="white"
      d="M165.78,55.79a1.06,1.06,0,1,0,1.19.9,1.06,1.06,0,0,0-1.19-.9"
    />
    <path
      fill="white"
      d="M162.79,59.76a1.06,1.06,0,1,0,1.19.9,1.06,1.06,0,0,0-1.19-.9"
    />
    <path
      fill="white"
      d="M159.71,63.85a1.06,1.06,0,0,0,.3,2.09,1.06,1.06,0,0,0-.3-2.09"
    />
    <path
      fill="white"
      d="M183.31,35.65l-8.53-6.48a2.63,2.63,0,0,0-3.71.51l-9.54,12.54a2.66,2.66,0,0,0,.5,3.72l8.53,6.48a2.66,2.66,0,0,0,1.6.54,2.63,2.63,0,0,0,2.11-1.05l9.55-12.54a2.67,2.67,0,0,0-.51-3.72m-.83,2.7L172.93,50.9a1,1,0,0,1-1.35.18l-8.53-6.48a1,1,0,0,1-.18-1.36l9.54-12.55a1,1,0,0,1,.64-.37h.13a1,1,0,0,1,.58.2L182.29,37a1,1,0,0,1,.19,1.36m.66.51h0Z"
    />
    <path
      fill="white"
      d="M195.44,12.12l-1.08-.83a11.64,11.64,0,0,0-16.27,2.17L155.41,43.14l-6.29-6.43c-4.39-4.65-11.43-5.15-15.7-1.12l-.91.85c-4.27,4-4.17,11.1.23,15.76,0,0,16.52,16.74,17.16,17.27s1.08.83,1.08.83a11.62,11.62,0,0,0,16.27-2.17l30.37-39.74a11.61,11.61,0,0,0-2.18-16.27M134.13,50.87c-3.66-3.88-3.79-9.72-.3-13l.91-.86a8.19,8.19,0,0,1,5.69-2.2,10.11,10.11,0,0,1,7.3,3.25l6.5,6.64-6.51,8.52a11.57,11.57,0,0,0-2.27,8.59c0,.27.1.53.15.8Zm62-23.65L165.72,67a9.73,9.73,0,0,1-13.57,1.81l-1.08-.83a9.32,9.32,0,0,1-1.22-1.12,9.7,9.7,0,0,1-.6-12.45l6.34-8.3,1.18-1.54,22.85-29.9a9.69,9.69,0,0,1,13.57-1.81l1.08.83a9.69,9.69,0,0,1,1.82,13.57"
    />
    <path
      fill={fill}
      d="M16.43,2.11h7.42l-8,24.54H8L0,2.11H7.45l3.34,12.44a36.32,36.32,0,0,1,1.14,5.78c.08-.79.24-1.77.47-2.94s.45-2.1.63-2.77Z"
    />
    <path
      fill={fill}
      d="M36.46,26.65,35.2,24.14h-.13a7.67,7.67,0,0,1-2.71,2.25,9,9,0,0,1-3.56.6,5.6,5.6,0,0,1-4.23-1.61A6.34,6.34,0,0,1,23,20.85a5.16,5.16,0,0,1,2.12-4.53,11.53,11.53,0,0,1,6.12-1.67l3.17-.1v-.27a2.06,2.06,0,0,0-2.31-2.35,13.86,13.86,0,0,0-5.29,1.41L24.94,9a18.31,18.31,0,0,1,8.4-1.71A8.14,8.14,0,0,1,39,9.11a6.48,6.48,0,0,1,2,5.07V26.65Zm-4.87-4.29a3,3,0,0,0,2-.76,2.49,2.49,0,0,0,.85-2V18.16L33,18.23c-2.16.08-3.24.87-3.24,2.38,0,1.16.62,1.75,1.86,1.75"
    />
    <path
      fill={fill}
      d="M53.62,27Q49,27,46.54,24.47c-1.6-1.68-2.41-4.09-2.41-7.23s.87-5.6,2.61-7.34,4.2-2.6,7.4-2.6a13.93,13.93,0,0,1,6.23,1.44l-1.94,4.85a23.09,23.09,0,0,0-2.19-.83,7.36,7.36,0,0,0-2.1-.31,2.84,2.84,0,0,0-2.47,1.25,6,6,0,0,0-.87,3.5q0,4.57,3.37,4.57a10.2,10.2,0,0,0,5.54-1.68v5.22A10.77,10.77,0,0,1,53.62,27"
    />
    <path
      fill={fill}
      d="M76.86,26.65,76,24.29h-.39a5.32,5.32,0,0,1-2.29,2,7.9,7.9,0,0,1-3.45.7A6.45,6.45,0,0,1,65,25.17,7.13,7.13,0,0,1,63.28,20V7.64h6.56V18a6,6,0,0,0,.53,2.82,1.85,1.85,0,0,0,1.74.95,2.53,2.53,0,0,0,2.42-1.33A10.18,10.18,0,0,0,75.25,16V7.64h6.56v19ZM69,5.82V5.34c1.73-1.85,2.82-3,3.29-3.58s.85-1,1.15-1.44h7.18V.67q-1.35,1.08-3.69,2.74c-1.57,1.1-2.76,1.91-3.56,2.41Z"
    />
    <path
      fill={fill}
      d="M98.35,26.65V16.28a6,6,0,0,0-.55-2.86,1.85,1.85,0,0,0-1.75-1,2.54,2.54,0,0,0-2.4,1.33,9.93,9.93,0,0,0-.74,4.55v8.32H86.35v-19h5L92.12,10h.38a5.21,5.21,0,0,1,2.33-2,8.17,8.17,0,0,1,3.41-.67,6.47,6.47,0,0,1,4.91,1.83,7.15,7.15,0,0,1,1.76,5.15V26.65Z"
    />
    <path
      fill={fill}
      d="M121.09,26.65l-1.26-2.51h-.13A7.67,7.67,0,0,1,117,26.39a8.93,8.93,0,0,1-3.55.6,5.6,5.6,0,0,1-4.23-1.61,6.3,6.3,0,0,1-1.55-4.53,5.16,5.16,0,0,1,2.12-4.53,11.53,11.53,0,0,1,6.12-1.67l3.18-.1v-.27a2.07,2.07,0,0,0-2.32-2.35,13.86,13.86,0,0,0-5.29,1.41L109.58,9A18.23,18.23,0,0,1,118,7.3a8.12,8.12,0,0,1,5.66,1.81,6.48,6.48,0,0,1,2,5.07V26.65Zm-4.87-4.29a3,3,0,0,0,2-.76,2.49,2.49,0,0,0,.85-2V18.16l-1.51.07c-2.16.08-3.24.87-3.24,2.38,0,1.16.62,1.75,1.86,1.75"
    />
    <path
      fill={fill}
      d="M138.73,21.8a9.86,9.86,0,0,0,3.17-.65v4.78a13.39,13.39,0,0,1-2.52.82,14.5,14.5,0,0,1-2.81.24q-3.31,0-4.77-1.62a7.28,7.28,0,0,1-1.46-5V12.55H128V9.88L131,7.82l1.69-4H137V7.64h4.67v4.91H137V20c0,1.23.59,1.84,1.78,1.84"
    />
    <path
      fill={fill}
      d="M154.41,27q-4.75,0-7.38-2.53t-2.63-7.19c0-3.21.82-5.68,2.44-7.39s3.94-2.58,6.95-2.58a9.26,9.26,0,0,1,6.64,2.24A8.52,8.52,0,0,1,162.77,16v2.93H151a3.26,3.26,0,0,0,1.16,2.5,4.59,4.59,0,0,0,3,.9,15,15,0,0,0,3.18-.32,15.62,15.62,0,0,0,3.15-1.12V25.6a11.93,11.93,0,0,1-3.19,1.09,22.29,22.29,0,0,1-3.93.3M154,11.77a2.72,2.72,0,0,0-1.94.73,3.39,3.39,0,0,0-.91,2.29h5.64a3.25,3.25,0,0,0-.78-2.2,2.58,2.58,0,0,0-2-.82"
    />
  </svg>
);

export default LogoES;
