import React, { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
  Slide,
  IconButton,
} from "@material-ui/core";
import { TrackedButton } from "../../../common/TrackedComponents";
import { Close } from "@material-ui/icons";
import FiltersContent from "./FiltersContent";
import ClearFiltersButton from "./ClearFiltersButton";
import { tracking } from "../../../constants";
import { filtersContext } from "./index";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: theme.spacing(2, 2, 0),
  },
  dialogContent: {
    padding: theme.spacing(0, 2),
  },
  dialogClose: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: 8,
  },
  filtersButton: {
    marginBottom: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FiltersMobile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    handleClick,
    clearFilters,
    filtersAppliedCount,
    filtersData,
  } = useContext(filtersContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isClearButtonDisabled = useMemo(() => filtersAppliedCount === 0,
    [filtersAppliedCount]
  )

  return (
    <Grid item xs={12}>
      <TrackedButton
        event={tracking.OPEN_FILTERS_BUTTON_CLICKED}
        className={classes.filtersButton}
        color="primary"
        variant={"contained"}
        size="large"
        onClick={handleClickOpen}
        fullWidth
      >
        {t("filters")} ({filtersAppliedCount})
      </TrackedButton>
      <ClearFiltersButton
        fullWidth
        disabled={isClearButtonDisabled}
        onClick={clearFilters}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography
            component="h3"
            variant="h5"
          >
            {t("filters")}
          </Typography>
          <IconButton
            className={classes.dialogClose}
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FiltersContent handleClick={handleClick} filtersData={filtersData} />
        </DialogContent>
        <DialogActions>
          <ClearFiltersButton
            onClick={clearFilters}
            disabled={isClearButtonDisabled}
            fullWidth
          />
        </DialogActions>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
            disableElevation
            fullWidth
            size="large"
            variant="contained"
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
