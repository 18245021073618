import React from "react";
import { useTranslation } from "react-i18next";
import { Check } from "@material-ui/icons";
import {
  Chip,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconSpacer: {
    display: "inline-block",
    width: 24,
  },
  filterGroup: {
    border: 0,
    padding: 0,
    margin: theme.spacing(2, 0, 0)
  },
  label: {
    padding: 0,
    margin: theme.spacing(1, 0),
    fontWeight: 600,
  },
  chip: {
    height: "fit-content",
    padding: "4px 0",
    margin: theme.spacing(.5),
  },
  chipLabel: {
    lineHeight: "normal",
    paddingLeft: 8,
    paddingRight: 24,
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  }
}));

export default function FilterGroup({label, list, handleClick, filterProperty}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <fieldset className={classes.filterGroup}>
      <Typography
        className={classes.label}
        component="legend"
      >
      </Typography>
      {list.map((data) => (
        <Chip
          classes={{
            root: classes.chip,
            label: classes.chipLabel,
            message: classes.message,
          }}
          aria-pressed={data.isSelected}
          key={data.value}
          color={data.isSelected ? "primary" : "default"}
          variant={data.isSelected ? "outlined" : "default"}
          icon={data.isSelected? <Check /> : <span className={classes.iconSpacer} />}
          label={t(data.value)}
          onClick={handleClick(data, filterProperty)}
        />
      ))}
    </fieldset>
  );
}
