import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CopyContact from "./CopyContact";
import { TrackedButton } from "../../../../common/TrackedComponents";
import usePrevious from "../../../../hooks/usePrevious";
import { tracking, vaccineAvailability } from "../../../../constants";
import {
  CardActions,
  Divider,
  Collapse,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import { Accessible, ChildFriendly, Check, Email, Phone } from "@material-ui/icons";
import { ContactButton } from "./ContactButton";
import { WebsiteFullLink } from "./WebsiteFullLink";
import { get } from "lodash";
import clsx from "clsx";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(1.5),
  },
  actions: {
    padding: theme.spacing(0, 2, 1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  emailLink: {
    color: theme.palette.primary.main,
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2, 1),
  },
  infoItemText: {
    marginLeft: theme.spacing(1),
  },
  check: {
    color: theme.palette.accent.green,
  },
  accessibilityItem: {
    paddingLeft: theme.spacing(6),
  },
  interpreterDesc: {
    marginTop: theme.spacing(-2),
    paddingLeft: theme.spacing(10),
  },
}));

export const SecondaryInfoSection = ({ location, resultPosition }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const {
    schedulingLink,
    infoLink,
    email,
    phone,
    locationId,
    walkIn,
    waitList,
    walkupSite,
    driveupSite,
    waitingArea,
    additionalSupports,
    accessibleParking,
    wheelchairAccessible,
    commCardAvailable,
    commCardBrailleAvailable,
    interpretersAvailable,
    interpretersDesc,
    supportUrl,
    scheduleOnline,
    scheduleByPhone,
    scheduleByEmail,
    provideInfantVaccine,
    providePediatricVaccine,
    provideYouthVaccine,
    consentLegallyEmancipated,
    consentFosterParent,
    consentResponsibleRelative,
    consentMatureMinor,
    consentVerbal,
    consentWritten,
    consentFromParentGuardianAuthAdul,
    consentSignedAuth,
    consentNurseCounselorLiai,
  } = location;
  const schedulingMap = {
    scheduleOnline,
    scheduleByPhone,
    scheduleByEmail,
    walkIn,
    waitList,
  };
  const accessibilityMap = {
    walkupSite,
    driveupSite,
    waitingArea,
    additionalSupports,
    accessibleParking,
    wheelchairAccessible,
    commCardAvailable,
    commCardBrailleAvailable,
    interpretersAvailable,
  };
  const cysMap = {
    provideInfantVaccine,
    providePediatricVaccine,
    provideYouthVaccine,
    consentLegallyEmancipated,
    consentFosterParent,
    consentResponsibleRelative,
    consentMatureMinor,
    consentVerbal,
    consentWritten,
    consentFromParentGuardianAuthAdul,
    consentSignedAuth,
    consentNurseCounselorLiai,
  };
  const schedulingOptions = Object.keys(schedulingMap).filter(
    (key) => schedulingMap[key]
  );
  const accessibilityOptions = Object.keys(accessibilityMap).filter(
    (key) => accessibilityMap[key]
  );
  const cysOptions = Object.keys(cysMap).filter(
    (key) => cysMap[key]
  );
  const { language } = i18n;
  const timeDisplayed = Date.now();
  const hasAvailability =
    location.vaccineAvailability === vaccineAvailability.AVAILABLE;

  const [showMore, setShowMore] = useState(false);
  const previousShowMore = usePrevious(showMore);

  const viewMoreButtonRef = useRef(null);
  const expandedContentRef = useRef(null);

  const toggleShowMore = () => {
    setShowMore((x) => !x);
  };

  useEffect(() => {
    // User has just expanded the panel, focus on the first <a> within expanded content
    if (
      expandedContentRef.current &&
      previousShowMore === false &&
      showMore === true
    ) {
      const firstLink = document
        .getElementById(`expandedContent-${locationId}`)
        .getElementsByTagName("a")[0];

      if (firstLink) firstLink.focus();
    }
  }, [showMore, previousShowMore, locationId]);

  useEffect(() => {
    // User has just collapsed the panel, focus on the viewMore Button
    if (
      viewMoreButtonRef.current &&
      previousShowMore === true &&
      showMore === false
    )
      viewMoreButtonRef.current.focus();
  }, [showMore, previousShowMore]);

  const locationTrackingTags = {
    locationId,
    distance: location.distance,
    position: resultPosition,
    timeToClickSeconds: () => (Date.now() - timeDisplayed) / 1000,
    zip: location.zipcode,
    language,
  };

  const methodsOfContact = [
    { type: "website", value: schedulingLink || infoLink },
    { type: "email", value: email },
    { type: "phone", value: phone },
  ].filter((contact) => !!contact.value);

  const preferredMethodOfContact = get(methodsOfContact, '[0].type');
  const hasMore = methodsOfContact.length > 1 || schedulingOptions.length > 0 || accessibilityOptions.length > 0 || cysOptions.length > 0;

  const copyEmailDisplay = (
    <CopyContact
      display={
        <a
          className={classes.emailLink}
          href={`mailto:${email}`}
          rel="noopener noreferrer"
        >
          {email}
        </a>
      }
      copyValue={email}
      Icon={Email}
      event={tracking.LOCATIONS_EMAIL_COPIED}
      eventTags={locationTrackingTags}
    />
  );
  const phoneDisplay = (
    <ContactButton
      icon={<Phone />}
      displayText={phone}
      href={`tel:${phone}`}
      event={tracking.LOCATIONS_PHONE_LINK_CLICKED}
      eventTags={locationTrackingTags}
      buttonText={t("call")}
    />
  );

  return (
    <div className={classes.container}>
      {preferredMethodOfContact === "website" && (
        <CardActions className={classes.actions}>
          <WebsiteFullLink
            href={schedulingLink || infoLink}
            eventTags={locationTrackingTags}
            hasAvailability={hasAvailability}
            location={location}
          />
        </CardActions>
      )}
      {preferredMethodOfContact === "email" && copyEmailDisplay}
      {preferredMethodOfContact === "phone" && phoneDisplay}
      {hasMore && (
        <>
          <Collapse key={locationId} in={showMore} timeout="auto">
            <Divider className={classes.divider} />
            <div id={`expandedContent-${locationId}`} ref={expandedContentRef}>
              {email && preferredMethodOfContact !== "email" && copyEmailDisplay}
              {phone && preferredMethodOfContact !== "phone" && phoneDisplay}
              {schedulingOptions.length > 0 && (
                schedulingOptions.map((key) => (
                  <div key={key} className={classes.infoItem}>
                    <Check className={classes.check} />
                    <Typography
                      className={classes.infoItemText}
                      component="p"
                      variant="body1"
                    >
                      {t(key)}
                    </Typography>
                  </div>
                ))
              )}
              {accessibilityOptions.length > 0 && (
                <>
                  <div className={classes.infoItem}>
                    <Accessible />
                    <Typography
                      className={classes.infoItemText}
                      component="p"
                      variant="body1"
                    >
                      {t("accessibilityLabel")}
                    </Typography>
                  </div>
                  {accessibilityOptions.map((key) => (
                    <Fragment key={key}>
                      <div
                        className={clsx(
                          classes.infoItem,
                          classes.accessibilityItem
                        )}
                      >
                        <Check className={classes.check} />
                        <Typography
                          className={classes.infoItemText}
                          component="p"
                          variant="body1"
                        >
                          {t(key)}
                        </Typography>
                      </div>
                      {key === "interpretersAvailable" && interpretersDesc && (
                        <Typography
                          className={clsx(
                            classes.infoItem,
                            classes.interpreterDesc
                          )}
                          color="textSecondary"
                        >
                          {interpretersDesc}
                        </Typography>
                      )}
                    </Fragment>
                  ))}
                </>
              )}
              {cysOptions.length > 0 && (
                <>
                  <div className={classes.infoItem}>
                    <ChildFriendly />
                    <Typography
                      className={classes.infoItemText}
                      component="p"
                      variant="body1"
                    >
                      {t("cysLabel")}
                    </Typography>
                  </div>
                  {cysOptions.map((key) => (
                    <Fragment key={key}>
                      <div
                        className={clsx(
                          classes.infoItem,
                          classes.accessibilityItem
                        )}
                      >
                        <Check className={classes.check} />
                        <Typography
                          className={classes.infoItemText}
                          component="p"
                          variant="body1"
                        >
                          {t(key)}
                        </Typography>
                      </div>
                    </Fragment>
                  ))}
                </>
              )}
              {supportUrl && (
                <Link
                  className={clsx(
                    classes.infoItem,
                    classes.accessibilityItem
                  )}
                  href={supportUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t("moreAccessibilityInfo")}
                </Link>
              )}
            </div>
          </Collapse>
          <CardActions className={classes.actions}>
            {showMore ? (
              <TrackedButton
                aria-expanded
                onClick={toggleShowMore}
                color="primary"
                disableElevation
                event={tracking.LOCATIONS_VIEW_LESS_INFO_CLICKED}
                tags={locationTrackingTags}
                fullWidth
                size="large"
                variant={"outlined"}
              >
                {t("viewLess")}
              </TrackedButton>
            ) : (
              <TrackedButton
                ref={viewMoreButtonRef}
                aria-expanded={false}
                onClick={toggleShowMore}
                color="primary"
                disableElevation
                event={tracking.LOCATIONS_VIEW_MORE_INFO_CLICKED}
                tags={locationTrackingTags}
                fullWidth
                size="large"
                variant={"outlined"}
              >
                {t("viewMoreOptions")}
              </TrackedButton>
            )}
          </CardActions>
        </>
      )}
    </div>
  );
};

SecondaryInfoSection.propTypes = {
  location: PropTypes.object.isRequired,
  resultPosition: PropTypes.number,
};
