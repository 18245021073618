import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import {
  Card,
  CardContent,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Cancel, CheckCircle, NotListedLocation } from "@material-ui/icons";
import { SecondaryInfoSection } from "./SecondaryInfoSection/SecondaryInfoSection";
import { vaccineAvailability } from "../../../constants";
import InlineBoldTrans from "../../../common/InlineBoldTrans";

const availabilityStrings = {
  [vaccineAvailability.AVAILABLE]: "hasAppointments",
  [vaccineAvailability.UNAVAILABLE]: "hasNoAppointments",
  [vaccineAvailability.UNKNOWN]: "appointmentStatusUnknown",
};

const availabilityIcons = {
  [vaccineAvailability.AVAILABLE]: CheckCircle,
  [vaccineAvailability.UNAVAILABLE]: Cancel,
  [vaccineAvailability.UNKNOWN]: NotListedLocation,
};

const availabilityStyles = {
  [vaccineAvailability.AVAILABLE]: "availableIcon",
  [vaccineAvailability.UNAVAILABLE]: "noAvailabilityIcon",
  [vaccineAvailability.UNKNOWN]: "unknownAvailabilityIcon",
};

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  infoItem: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
  },
  infoItemMessage: {
    marginLeft: theme.spacing(0.5),
  },
  updateMessage: {
    marginLeft: theme.spacing(3.5),
  },
  actions: {
    padding: theme.spacing(0, 2, 2),
  },
  availableIcon: {
    color: theme.palette.accent.green,
  },
  noAvailabilityIcon: {
    color: theme.palette.warning.main,
  },
  unknownAvailabilityIcon: {
    color: theme.palette.warning.main,
  },
  rtlEnglishAddress: {
    textAlign: "left",
  },
  vaccineTypeIcon: {
    color: theme.palette.secondary.dark,
  },
}));

const LocationCard = memo(({ location, resultPosition }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const locationAddress = useMemo(() => {
    const { addressLine1, addressLine2, city, state, zipcode } = location;
    let address = addressLine1;

    if (addressLine2) address += ` ${addressLine2}`;

    return `${address}, ${city}, ${state} ${zipcode}`;
  }, [location]);

  const vaccineType = useMemo(() => {
    const { vaccineTypes } = location;
    return isEmpty(vaccineTypes)
      ? t("vaccineTypesNotAvailable")
      : vaccineTypes.join(", ");
  }, [location, t]);


  const recordSource = useMemo(() => {
    //we need to convert the API Function name to an Acronym

    const { rawDataSourceName } = location;

    switch (rawDataSourceName) {
      case 'CostcoLocationsFn':
      case 'CostcoVaccineAvailabilityFn':
        return 'CC';
      case 'CVSVaccineAvailabilityFn':
        return 'CV';
      case 'EpicVaccineAvailabilityFn':
        return 'EV';
      case 'KPWAEpicVaccineAvailabilityFn':
        return 'KP';
      case 'PrepModVaccineAvailabilityFn':
        return 'PM';
      case 'RiteAidVaccineAvailabilityFn':
        return 'RA';
      case 'UWEpicVaccineAvailabilityFn':
        return 'UW';
      case 'VaccineFinderAvailabilityFn':
        return 'VF';
      case 'VaccineSpotterFn':
        return 'VS';
      case 'ValleyMedEpicVaccineAvailabilityFn':
        return 'VM';
      case 'WalgreensFn':
        return 'WG';
      case 'WADOH':
          return 'WA';
      default:
        return 'UN';
    }
  }, [location]);
  // TODO: figure out that location.type should be in the new data schema

  const AvailabilityIcon = availabilityIcons[location.vaccineAvailability];
  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <div className={classes.title}>
          <Typography component="h2" gutterBottom variant="h4">
            {location.locationName}
          </Typography>
          <Typography
            className={clsx({
              [classes.rtlEnglishAddress]: theme.direction === "rtl",
            })}
            component="p"
            dir="ltr"
            variant="body1"
          >
            {locationAddress}
          </Typography>
          <Typography component="p" variant="body1">
            {location.formattedDistance} mi
          </Typography>
        </div>
        <div className={classes.infoItem}>
          <span
            role="img"
            className={clsx("material-icons", classes.vaccineTypeIcon)}
            aria-label={t("vaccineTypes")}
          >
            medical_services
          </span>
          <Typography
            className={classes.infoItemMessage}
            component="p"
            variant="body1"
          >
            <InlineBoldTrans i18nKey={vaccineType} />
          </Typography>
        </div>
        {/* <div className={classes.infoItem}>
          <AvailabilityIcon
            className={
              classes[availabilityStyles[location.vaccineAvailability]]
            }
          />
          <Typography
            className={classes.infoItemMessage}
            component="p"
            variant="body1"
          >
            {t(availabilityStrings[location.vaccineAvailability])}
          </Typography>
        </div> */}
        <Typography
          className={classes.updateMessage}
          color="textSecondary"
          component="p"
          variant="caption"
        >
          {t("updatedAt")} {moment(location.updatedAt).format('l') + ' ' +moment(location.updatedAt).format('LT') } ({recordSource})
        </Typography>
      </CardContent>
      <SecondaryInfoSection location={location} resultPosition={resultPosition} />
    </Card>
  );
});

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
  resultPosition: PropTypes.number,
};

export default LocationCard;
