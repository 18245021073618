import React, { useState, useCallback, useEffect } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { TrackedLink } from "../../../../common/TrackedComponents";
import PropTypes from "prop-types";

const SHOW_RESULT_TIME = 2000;
const useStyles = makeStyles((theme) => ({
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2, 1),
  },
  contactItemText: {
    marginLeft: theme.spacing(1),
  },
  copyButton: {
    marginLeft: "auto",
    textDecoration: "none",
  },
  copyText: {
    fontWeight: 600,
  },
  doneIcon: {
    fontSize: "1rem",
  }
}));

const CopyContact = ({ display, copyValue, Icon, event, eventTags }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [copyResult, copyToClipboard] = useCopyToClipboard();
  const [didCopyResult, setDidCopyResult] = useState(false);

  const onCopyClick = useCallback(
    () => {
      return copyToClipboard(copyValue);
    },
    [copyToClipboard, copyValue]
  );

  useEffect(() => {
    if (!copyResult) return;
    setDidCopyResult(true);
    setTimeout(() => {
      setDidCopyResult(false);
    }, SHOW_RESULT_TIME);
  }, [copyResult]);

  return (
    <div className={classes.contactItem}>
      <Icon />
      <Typography
        className={classes.contactItemText}
        component="p"
        variant="body1"
      >
        {display}
      </Typography>
      <TrackedLink
        component="button"
        event={event}
        tags={eventTags}
        className={classes.copyButton}
        color="primary"
        size={"small"}
        onClick={e => onCopyClick(e)}
      >
        <Typography
          className={classes.copyText}
          component="p"
          variant="body1"
        >
          { didCopyResult && copyResult.value ? (
            <Done className={classes.doneIcon} />
          ) : (
            t("copy")
          )}
        </Typography>
      </TrackedLink>
    </div>
  );
};

export default React.memo(CopyContact);

CopyContact.propTypes = {
  display: PropTypes.element,
  copyValue: PropTypes.string,
  Icon: PropTypes.object,
  event: PropTypes.string,
  eventTags: PropTypes.object,
};
