import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Collapse,
  Grid,
  makeStyles,
  Container,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import FiltersContent from "./FiltersContent";
import ClearFiltersButton from "./ClearFiltersButton";
import { tracking } from "../../../constants";
import useEventTracker from "../../../hooks/useEventTracker";
import { filtersContext } from "./index";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    "@media (min-width: 686px)": { // 686px is min width for 16 px for margin since search bar is centered
      marginLeft: `calc((100% - 654px) / 2)`, // 650px is max the searchInput length will get
    },
  },
  filtersToggle: {
    fontSize: "1.25rem",
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
  },
  expandedSection: {
    marginLeft: theme.spacing(1),
  },
}));

export default function FiltersDesktop() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    openVF,
    setOpenVF,
    openSP,
    setOpenSP,
    openAC,
    setOpenAC,
    openCYS,
    setOpenCYS,
    handleClick,
    clearFilters,
    filtersAppliedCount,
    filtersData,
  } = useContext(filtersContext);

  const trackOpenFilters = useEventTracker(
    tracking.OPEN_FILTERS_BUTTON_CLICKED
  );

  const toggleClickOpen = () => {
    if(!open) {
      trackOpenFilters();
    }
    setOpen((x) => !x);
  }

  return (
    <Grid className={classes.wrapper} item xs={12}>
      <Button
        className={classes.filtersToggle}
        size="large"
        aria-expanded={open}
        onClick={toggleClickOpen}
      >
        {t("filters")} ({filtersAppliedCount})
        { open ? <ExpandLess /> : <ExpandMore /> }
      </Button>
      <ClearFiltersButton
        onClick={clearFilters}
        disabled={filtersAppliedCount === 0}
      />
      <Collapse
        className={classes.expandedSection}
        in={open}
        timeout="auto"
      >
        <FiltersContent
          handleClick={handleClick}
          filtersData={filtersData}
        />
      </Collapse>
    </Grid>
  );
}
