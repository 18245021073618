import React from 'react'
import { Helmet } from 'react-helmet'

const ga = gaProfileId => `
  window.dataLayer = window.dataLayer || [];

  function gtag(){dataLayer.push(arguments);}

  gtag('js', new Date());

  gtag('config', '${gaProfileId}');
`

const GoogleAnalytics = () => {
  const id = process.env.REACT_APP_GA_ID
  if (!id) {
    console.warn('Google Analytics not loaded: no ID in build environment')
    return null
  }

  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
      <script>{ga(id)}</script>
    </Helmet>
  )
}

export default GoogleAnalytics
