import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Container, Typography, makeStyles } from '@material-ui/core';

import EligibilityRequirements from '../EligibilityRequirements';
import NotificationBanner from '../NotificationBanner';
import SEO from '../../../common/SEO';
import SearchInput from '../SearchInput';
import ZipsInternalNav from '../ZipsInternalNav';
import Schema from '../schema/SearchAction';

const useStyles = makeStyles((theme) => ({
  welcomeSection: {
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 3),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      height: '55vh',
      justifyContent: 'center',
      padding: 0,
    },
  },
  title: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      ...theme.typography.h2,
      marginBottom: theme.spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 560,
      textAlign: 'center',
    },
  },
}));

const LocationFinder = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const zips = useSelector((state) => Object.keys(state.locationFinder.zips));

  return (
    <>
      {/*<SEO description={t('pageDescriptionHome')} title={t('pageTitleHome')} />
      <NotificationBanner />*/}
      <Container className={classes.welcomeSection} maxWidth='md'>
        <div>
          <p>
            Washington State's COVID-19 Vaccine Locator is no longer supported. Thank you for using this valuable tool over the last few years! Please visit{' '}
            <a href='https://www.vaccines.gov/en/' target='_blank'>
              Vaccines.gov
            </a>{' '}
            to help you find pharmacies offering COVID-19 vaccine, and other vaccines, near you.
          </p>
          <p>
            The{' '}
            <a href='https://doh.wa.gov/' target='_blank'>
              Washington State Department of Health
            </a>{' '}
            also supports this{' '}
            <a href='https://wadoh.maps.arcgis.com/apps/webappviewer/index.html?id=d35642cbbd32419787341d2e27d9a75f' target='_blank'>
              provider map
            </a>
            . This map is used to find providers who receive publicly supplied vaccines through the state's{' '}
            <a
              href='https://doh.wa.gov/public-health-provider-resources/public-health-system-resources-and-services/immunization/childhood-vaccine-program'
              target='_blank'
            >
              Childhood Vaccine Program
            </a>{' '}
            and{' '}
            <a
              href='https://doh.wa.gov/public-health-provider-resources/public-health-system-resources-and-services/immunization/adult-vaccine-program'
              target='_blank'
            >
              Adult Vaccine Program
            </a>
            .
          </p>
          <p>
            <em>
              Please note: This provider map is updated twice each year. Contact the provider ahead of your visit to confirm availability of supply, age
              eligibility, and more information regarding the policies associated with the practice.
            </em>
          </p>
        </div>
      </Container>
      {/*<EligibilityRequirements />
      <ZipsInternalNav zips={zips} />
      <Schema />*/}
    </>
  );
};

export default LocationFinder;
