import PropTypes from "prop-types";
import React, {useContext} from "react";
import { useTranslation } from "react-i18next";
import {
  makeStyles,
  Typography,
  Button,
  Collapse,
  Grid,
  Container
} from "@material-ui/core";
import FilterGroup from './FilterGroup';
import { filterProperty } from "../../../constants";
import { tracking } from "../../../constants";
import useEventTracker from "../../../hooks/useEventTracker";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { filtersContext } from "./index";

const { VACCINE_TYPES, SCHEDULING_FILTERS,  ACCESSIBILITY_FILTERS, CYS_FILTERS } = filterProperty;
const useStyles = makeStyles((theme) => ({
  filterGroup: {
    display: "flex",
    flexWrap: "wrap",
    border: 0,
    padding: 0,
    margin: 0,
  },
}));

export default function FiltersContent({ filtersData, handleClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    openVF,
    setOpenVF,
    openSP,
    setOpenSP,
    openAC,
    setOpenAC,
    openCYS,
    setOpenCYS,
    clearFilters,
    filtersAppliedCount
  } = useContext(filtersContext);

  const schedulingPreferencesList = filtersData.schedulingFilters;
  const accessibilityList = filtersData.accessibilityFilters;
  const cysList = filtersData.cysFilters;

  const toggleClickVTOpen = () => {  
    setOpenVF((x) => !x);
  }

  const toggleClickSPOpen = () => {    
    setOpenSP((x) => !x);
  }

  const toggleClickACOpen = () => {
    setOpenAC((x) => !x);
  }

  const toggleClickCYSOpen = () => {
    setOpenCYS((x) => !x);
  }

  return (
    <>

      {/* 
      
          ADO 1819 Add an "Accordian" Control for Each Filter Section
      
          For each collapsible filter section the components consists of the following  
             ELEMENT                      USAGE
          1. A Button element             Used to trigger the update to the Collapse element based on the state of the control.
          2. A Collapse element           Toggles content from visible to hidden in a collapsible style
          3. A FilterGroup element        Contains the filter data that users can select 
          EXCEPTION: 
          4. A Typography element         Used to display the message regarding boosters.

      */}

      {/* Vaccine Type filter collapsible section */}
      <Button
        className={classes.filtersToggle}
        size="large"
        aria-expanded={openVF}
        onClick={toggleClickVTOpen}>
        {t("vaccineTypes")}
        { openVF ? <ExpandLess /> : <ExpandMore /> }
      </Button>
      <Collapse
        className={classes.expandedSection}
        in={openVF}
        timeout="auto">

        <FilterGroup
          filterProperty={VACCINE_TYPES}
          list={filtersData.vaccineTypes}
          handleClick={handleClick}/>
        <Typography
          aria-live="polite"
          className={classes.message}
          color="textSecondary"
          component="body2"
          variant="body2">
          {t("msgBoosters")}
        </Typography> 

      </Collapse>

      {/* Scheduling preferences filter collapsible section */}

      <Button
        className={classes.filtersToggle}
        size="large"
        aria-expanded={openVF}
        onClick={toggleClickSPOpen}>
        {t("schedulingPreferences")}
        { openSP ? <ExpandLess /> : <ExpandMore /> }
      </Button>
      <Collapse
        className={classes.expandedSection}
        in={openSP}
        timeout="auto">
      
        <FilterGroup
          filterProperty={SCHEDULING_FILTERS}
          list={schedulingPreferencesList}
          handleClick={handleClick}/>

      </Collapse>

      {/* Accessibility filter collapsible section */}

      <Button
        className={classes.filtersToggle}
        size="large"
        aria-expanded={openAC}
        onClick={toggleClickACOpen}>
        {t("accessibilityLabel")}
        { openAC ? <ExpandLess /> : <ExpandMore /> }
      </Button>
      <Collapse
        className={classes.expandedSection}
        in={openAC}
        timeout="auto">

        <FilterGroup
          filterProperty={ACCESSIBILITY_FILTERS}
          list={accessibilityList}
          handleClick={handleClick}/>

      </Collapse>

      {/* Child Youth Services filter collapsible section */}

      <Button
        className={classes.filtersToggle}
        size="large"
        aria-expanded={openCYS}
        onClick={toggleClickCYSOpen}>
        {t("cysLabel")}
        { openCYS ? <ExpandLess /> : <ExpandMore /> }
      </Button>
      <Collapse
        className={classes.expandedSection}
        in={openCYS}
        timeout="auto">

        <FilterGroup
          filterProperty={CYS_FILTERS}
          list={cysList}
          handleClick={handleClick}/>

      </Collapse>
    </>
  );
}

FiltersContent.propTypes = {
  filtersData: PropTypes.object,
  handleClick: PropTypes.func,
};
