import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { links } from "../../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5, 0),
    },
  },
  requirements: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  title: {
    textAlign: "center",
  },
  infolink: {
    color: "#CCFEFF",
    fontWeight: "600",
  },
}));

const ElegibilityRequirements = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.requirements} item xs={12}>
    <Container className={classes.container} maxWidth="sm">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography className={classes.title} component="p">
            <Link className={classes.infolink} target="_blank" href={t("dohVaccineLinkUrl")}>{t("dohVaccineLinkText")}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  </Grid>
  );
};

export default ElegibilityRequirements;
