import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { locales } from "./Header/LanguageSelect";

function SEO (props) {
  const {
    description,
    title
  } = props;

  const location = useLocation();
  const { i18n: { language } } = useTranslation();
  const origin = window.location.origin;

  // The canonical tag tells search engines what the "official, most definitive
  // version of this content lives". It helps us disambiguate from among
  // language-specific versions of the site as well as for query parameters that
  // don't affect the content of the page
  const canonical = React.useMemo(() => (
    `${origin}${location.pathname}?language=${language}`
  ), [language, location, origin])

  return (
    <Helmet
      htmlAttributes={{ lang: language }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          description: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: 'Washington DOH'
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: description
        },
      ]}
    >
      <link rel="canonical" href={canonical} />
      {
        // Signal to search engines which page variant to show if it is aware of
        // the searcher's language preferences. (https://moz.com/learn/seo/hreflang-tag)
        locales
        // No need to link the language version we're already on!
        .filter(({ id: lang }) => lang !== language)
        .map(({ id: lang }) => (
          <link
            href={`${origin}${location.pathname}?language=${lang}`}
            hreflang={lang}
            key={lang}
            rel="alternate"
          />
        ))
      }
    </Helmet>
  );
}

SEO.defaultProps = {
  description: `
    Publishing up-to-date COVID-19 vaccine availability at hospitals and
    pharmacies across Washington state.
  `
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default React.memo(SEO);
