import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { QuestionAnswer } from '@material-ui/icons';
import VaccineFinderLogo from './VaccineFinderLogo';
import { links } from '../../constants';
import InlineBoldTrans from '../../common/InlineBoldTrans';

const useStyles = makeStyles((theme) => ({
  feedback: {
    backgroundColor: theme.palette.accent.purpleLightest,
    padding: theme.spacing(4, 0),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 0),
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  logoContainer: {
    margin: '0 auto',
    padding: theme.spacing(4, 3),
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 420,
      padding: theme.spacing(6, 0),
    },
  },
  attribution: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 330,
    textAlign: 'center',
  },
  vaccineFinderLink: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      {/*<Grid className={classes.feedback} item xs={12}>
        <div className={classes.titleContainer}>
          <QuestionAnswer color="primary" />
          <Typography
            className={classes.title}
            component="h3"
            variant="subtitle1"
          >
            {t("feedback")}
          </Typography>
        </div>
        <Typography>
          {t("contactUs")}
          <br />
          <Link color="secondary" href={`mailto:${links.FEEDBACK_EMAIL}`}>
            {links.FEEDBACK_EMAIL}
          </Link>
          <br />
          <br />
          <Grid className={classes.notification} item xs={12}>
            <Container disableGutters maxWidth="md">
              <Typography>
                <InlineBoldTrans i18nKey="websiteDisclaimer" />
              </Typography>
            </Container>
          </Grid>
        </Typography>
      </Grid>
      <Grid className={classes.logoContainer} item xs={12}>
        <Link
          aria-label="VaccineFinder website"
          className={classes.vaccineFinderLink}
          href={links.VACCINEFINDER_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <VaccineFinderLogo width={160} />
        </Link>
        <div className={classes.attribution}>
          <Typography component="p" variant="caption">
            <Trans i18nKey="vaccineFinderAttribution"
              components={{year: currentYear}}>
              Vaccine type data provided by{" "}
              <Link
                color="secondary"
                href={links.VACCINEFINDER_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                VaccineFinder
              </Link>{" "}
              ©2021. Boston Children's Hospital. All rights reserved.
            </Trans>
          </Typography>
        </div>
      </Grid>*/}
    </footer>
  );
};

export default Footer;
