import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: 'none',
  }
});

function ZipsInternalNav (props) {
  const { zips } = props;
  const classes = useStyles();

  if (!zips.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <p>{'Find vaccination locations for your ZIP code'}</p>
      <ul>
        {zips.map(zip => (
          <li key={zip}>
            <Link to={`/locations/${zip}`}>{zip}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

ZipsInternalNav.propTypes = { zips: PropTypes.array };

export default React.memo(ZipsInternalNav)
