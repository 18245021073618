import { gql } from "@apollo/client";

export const findZips = gql`
  query FindZips {
    findZipCodes
  }
`;
export const searchLocations = gql`
  query SearchLocations($searchInput: SearchLocationsInput) {
    searchLocations(searchInput: $searchInput) {
      paging {
        pageSize
        pageNum
        total
      }
      summary {
        total
        vaccineAvailability
      }
      locations {
        addressLine1
        addressLine2
        city
        latitude
        locationId
        locationName
        locationType
        longitude
        schedulingLink
        state
        vaccineAvailability
        vaccineTypes
        zipcode
        updatedAt
        distance
        phone
        email
        infoLink
        waitList
        walkIn
        walkupSite
        driveupSite
        waitingArea
        additionalSupports
        accessibleParking
        wheelchairAccessible
        commCardAvailable
        commCardBrailleAvailable
        interpretersAvailable
        interpretersDesc
        supportUrl
        scheduleByEmail
        scheduleByPhone
        scheduleOnline
        provideInfantVaccine
        providePediatricVaccine
        provideYouthVaccine
        consentLegallyEmancipated
        consentFosterParent
        consentResponsibleRelative
        consentMatureMinor
        consentVerbal
        consentWritten
        consentFromParentGuardianAuthAdul
        consentSignedAuth
        consentNurseCounselorLiai
        rawDataSourceName
      }
    }
  }
`;
